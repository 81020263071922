import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

const ConfigureBuildStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontFamily: "cera-pro-bold",
      textTransform: "uppercase",
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(1),
    },
    subtitle: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
    },
  })
);

export default ConfigureBuildStyles;
