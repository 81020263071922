import React, { useEffect, useState, ChangeEvent } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import { Replay, Upload as UploadIcon } from "@mui/icons-material";
import {
  Button,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  MenuItem,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

import {
  DataDialog,
  DataPreview,
  DataValidation,
} from "@/components/molecules";

import { validateData } from "@/api/validate-data";
import { RowsExtractor } from "@/utils";

import { delimiters } from "@/constants";
import { FileInfo, ValidationResult } from "@/types";

import useUploadStyles from "./Upload.styles";

interface UploadProps {
  fileInfo: FileInfo | null;
  onFileUpdate: (
    file: File | null,
    delimiter: string | null,
    hasHeader: boolean,
    header: string | null,
    confirm: boolean,
    preview: string[] | null,
    valid: boolean
  ) => void;
  onSegmentsUpdate: (segments: string[]) => void;
}

const Upload: React.FC<UploadProps> = ({
  fileInfo,
  onFileUpdate,
  onSegmentsUpdate,
}) => {
  const styles = useUploadStyles();
  const [file, setFile] = useState<File | null>(null);
  const [delimiter, setDelimiter] = useState<string | null>(null);
  const [hasHeader, setHasHeader] = useState<boolean>(false);
  const [header, setHeader] = useState<string | null>(null);
  const [valid, setValid] = useState<boolean>(false);
  const [validLoading, setValidLoading] = useState<boolean>(false);
  const [validError, setValidError] = useState<boolean>(false);
  const [confirm, setConfirm] = useState<boolean>(false);
  const [rows, setRows] = useState<string[] | null>(null);
  const [togglePreview, setTogglePreview] = useState<boolean>(false);
  const [results, setResults] = useState<ValidationResult | null>(null);
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    if (fileInfo) {
      setFile(fileInfo.file);
      setDelimiter(fileInfo.delimiter);
      setHasHeader(fileInfo.hasHeader);
      setHeader(fileInfo.header);
      setConfirm(fileInfo.confirm);
      setRows(fileInfo.preview);
    }
  }, [fileInfo]);

  const handleFileInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { files } = e.currentTarget;
    if (files) {
      const rowsExtractor = new RowsExtractor(files[0], 50);
      rowsExtractor.promise.then((extractedRows) => {
        onFileUpdate(files[0], ",", true, "", false, extractedRows, false);
      });
      setValid(false);
      setValidLoading(false);
      setValidError(false);
    }
  };

  const handleDelimiterSelect = (e: ChangeEvent<HTMLInputElement>) => {
    const selectedDelimiter = e.target.value;
    if (file) {
      onFileUpdate(
        file,
        selectedDelimiter,
        hasHeader,
        header,
        false,
        rows,
        false
      );
    }
    setDelimiter(selectedDelimiter);
    setValid(false);
  };

  const handleHasHeaderChange = () => {
    if (file) {
      onFileUpdate(file, delimiter, !hasHeader, "", false, rows, false);
    }
    setHasHeader((prevHasHeader) => !prevHasHeader);
    setValid(false);
  };

  const handleHeaderChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newHeader = e.target.value;
    onFileUpdate(file, delimiter, hasHeader, newHeader, false, rows, false);
    setHeader(newHeader);
    setValid(false);
  };

  const handlePreviewOpen = () => setTogglePreview(true);
  const handlePreviewClose = () => setTogglePreview(false);

  const handleReset = () => {
    onFileUpdate(null, ",", true, "", false, [], false);
    setFile(null);
    setValid(false);
    setValidLoading(false);
    setValidError(false);
  };

  const handleValidateData = async () => {
    const accessToken = await getAccessTokenSilently();

    if (!validLoading) {
      setValidError(false);
      setValidLoading(true);

      const { data, error } = await validateData(
        accessToken,
        hasHeader,
        header || "",
        delimiter || ",",
        fileInfo?.file
      );

      if (error) {
        setValidError(true);
        setValidLoading(false);
        return;
      }

      if (data.data && data.schema)
        onFileUpdate(file, delimiter, hasHeader, header, true, rows, true);

      if ("segments" in data)
        onSegmentsUpdate(data["segments"].map((segment) => segment.segment));

      setResults(data);
      setValid(true);
      setValidLoading(false);
    }
  };

  return (
    <Grid container spacing={3} alignItems="center" alignContent="center">
      <Grid item xs={12}>
        <Typography
          gutterBottom
          variant="h6"
          fontWeight="bold"
          textTransform="uppercase"
        >
          Upload
        </Typography>
        <Typography variant="body1" gutterBottom>
          Upload a CSV file containing your customer postcodes, segments
          (optional), and define the file format.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Your data needs to contain a <strong>postcode</strong> column.
        </Typography>
        <Typography variant="body1" gutterBottom>
          If your data has segments, then the column name needs to be{" "}
          <strong>segment</strong>.
        </Typography>
        <Grid item xs={3} style={{ paddingTop: "20px", paddingBottom: "20px" }}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell className={styles.tableHeader}>postcode</TableCell>
                <TableCell className={styles.tableHeader}>segment</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>ABC 123</TableCell>
                <TableCell>Segment 1</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>ABC 456</TableCell>
                <TableCell>Segment 2</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
        <Typography variant="body1" gutterBottom>
          Use the <strong>Preview</strong> and <strong>Validate</strong> buttons
          to check that your file is configured correctly and contains valid
          postcodes.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2} alignItems="center" alignContent="center">
          <Grid item xs={12}>
            <Paper elevation={0} className={styles.fileInput}>
              <div className={styles.fileInputGroup}>
                <div className={styles.fileText}>
                  {file ? (
                    <Typography
                      color="textSecondary"
                      className={styles.marginText}
                    >
                      {file.name}
                    </Typography>
                  ) : (
                    <Typography>Choose a file (Max 150Mb):</Typography>
                  )}
                </div>
                <input
                  accept="text/csv"
                  id="contained-button-file"
                  type="file"
                  hidden
                  onChange={handleFileInputChange}
                />
                <div className={styles.end}>
                  {file && (
                    <Tooltip title="Reset file input" placement="bottom">
                      <IconButton onClick={handleReset}>
                        <Replay />
                      </IconButton>
                    </Tooltip>
                  )}
                  <Tooltip
                    title=".csv file containing postcodes column"
                    placement="bottom"
                  >
                    <label htmlFor="contained-button-file">
                      <Button
                        variant="contained"
                        color="primary"
                        component="span"
                      >
                        <UploadIcon className={styles.buttonIcon} />
                        Browse Files
                      </Button>
                    </label>
                  </Tooltip>
                </div>
              </div>
            </Paper>
          </Grid>
          {file && (
            <>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="field-delimiter"
                  label="Field Delimiter"
                  variant="filled"
                  select
                  value={delimiter}
                  onChange={handleDelimiterSelect}
                  InputProps={{
                    className: styles.textBox,
                    disableUnderline: true,
                  }}
                >
                  {delimiters.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  className={styles.headerSections}
                  control={
                    <Switch
                      checked={hasHeader}
                      size="small"
                      value="hasHeader"
                      onChange={handleHasHeaderChange}
                      color={confirm ? "secondary" : "primary"}
                    />
                  }
                  label="Does the file have a column header?"
                  labelPlacement="start"
                />
              </Grid>
              {!hasHeader && (
                <Grid item xs={12}>
                  <TextField
                    id="header-input"
                    fullWidth
                    label="Header"
                    multiline
                    variant="filled"
                    rows={3}
                    helperText="Please enter the header fields, one per line"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      className: styles.textBox,
                      disableUnderline: true,
                    }}
                    onChange={handleHeaderChange}
                    placeholder="cust_id postcode"
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <DataPreview
                  onClick={handlePreviewOpen}
                  fileConfirmed={confirm}
                />
              </Grid>
              <Grid item xs={12}>
                <DataValidation
                  onDataCheck={handleValidateData}
                  results={results}
                  valid={valid}
                  validLoading={validLoading}
                  validError={validError}
                  fileConfirmed={confirm}
                />
              </Grid>
            </>
          )}
        </Grid>
        <DataDialog
          open={togglePreview}
          onClose={handlePreviewClose}
          data={rows || []}
          delimiter={delimiter || ","}
          hasHeader={hasHeader}
          header={header || ""}
        />
      </Grid>
    </Grid>
  );
};

export default Upload;
