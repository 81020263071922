import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import usePageLayoutStyles from "./PageLayout.styles";

interface PageLayoutProps {
  name: string;
  description: string;
  onBack: () => void;
  children: React.ReactNode;
}

const PageLayout: React.FC<PageLayoutProps> = ({
  name,
  description,
  onBack,
  children,
}) => {
  const [open, setOpen] = useState(false);
  const styles = usePageLayoutStyles();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <IconButton onClick={onBack}>
          <HomeRoundedIcon />
        </IconButton>
        <div className={styles.centerText}>
          <Typography variant="h5" className={styles.boldTitle}>
            {name}
          </Typography>
        </div>
        <IconButton className={styles.info} onClick={handleOpen}>
          <InfoIcon />
        </IconButton>
        <Dialog
          open={open}
          onClose={handleClose}
          maxWidth="md"
          fullWidth
          aria-labelledby="info-title"
          aria-describedby="info-description"
          classes={{ paper: styles.dialogPaper }}
        >
          <DialogContent>
            <Typography dangerouslySetInnerHTML={{ __html: description }} />
          </DialogContent>
        </Dialog>
      </div>
      <div className={styles.content}>{children}</div>
    </div>
  );
};

export default PageLayout;
