import {
  StepConnector,
  stepConnectorClasses,
  styled,
  Theme,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

export const useGlobalStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginBottom: "2em",
      paddingTop: "24px",
      paddingBottom: "24px",
    },
    stepper: {
      marginTop: 1,
      marginBottom: 44,
    },
    stepPaper: {
      backgroundColor: "transparent",
    },
    stepLabel: {
      textTransform: "uppercase",
    },
    stepLabelBold: {
      textTransform: "uppercase",
      fontWeight: "bold",
    },
    stepIconRoot: {
      backgroundColor: theme.palette.primary.main,
      zIndex: 1,
      color: "#fff",
      width: 50,
      height: 50,
      display: "flex",
      borderRadius: "50%",
      justifyContent: "center",
      alignItems: "center",
    },
    stepIconActive: {
      backgroundColor: theme.palette.primary.main,
      boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
    },
    stepIconCompleted: {
      backgroundColor: theme.palette.primary.main,
    },
    rightButton: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
    },
  })
);

export const PathStepConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.primary.main,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.primary.main,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: "#E4E4E4",
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));
