import {createTheme} from "@mui/material/styles";
import {ThemeProvider} from "@mui/system";
import React, { createContext, useContext, useState } from "react";

interface ThemeContextType {
  darkMode: boolean;
  toggleDarkMode: () => void;
}

const ThemeContext = createContext<ThemeContextType | undefined>(undefined);

export const ThemeProviderWrapper: React.FC = ({ children }) => {
  const [darkMode, setDarkMode] = useState(true);

  const darkTheme = createTheme({
    palette: {
      mode: "dark",
      primary: {
        main: "#82e1f2",
        light: "#b3edf7",
        dark: "#00b1cc",
        contrastText: "#212b3a",
      },
      secondary: {
        main: "#F5B49F",
        light: "#f8d1c4",
        dark: "#EA673B",
        contrastText: "#212b3a",
      },
      info: {
        main: "#e6f0ff",
        light: "#eef8ff",
        dark: "#cad4ec",
        contrastText: "#212b3a",
      },
      warning: {
        main: "#FFE081",
        light: "#fff8e1",
        dark: "#ffb200",
        contrastText: "#212b3a",
      },
      error: {
        main: "#fa8996",
        light: "#ffe8ee",
        dark: "#f3002c",
        contrastText: "#212b3a",
      },
      background: {
        default: "#232D3D",
        paper: "#0B1627",
      },
    },
    typography: {
      fontFamily: "cera-pro",
    },
    components: {
      MuiFilledInput: {
        defaultProps: {
          disableUnderline: true,
        },
        styleOverrides: {
          root: {
            borderRadius: "4px",
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            borderRadius: "6px",
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            borderRadius: "6px",
          },
        },
      },
    },
  });

  const lightTheme = createTheme({
    palette: {
      mode: "light",
      primary: {
        main: "#00b1cc",
        light: "#82e1f2",
        dark: "#00656D",
        contrastText: "#ffffff",
      },
      secondary: {
        main: "#EA673B",
        light: "#F5B49F",
        dark: "#b54c29",
        contrastText: "#ffffff",
      },
      info: {
        main: "#1C2636",
        light: "#404956",
        dark: "#0B1627",
        contrastText: "#ffffff",
      },
      warning: {
        main: "#ffb200",
        light: "#FFE081",
        dark: "#ff6e00",
        contrastText: "#212b3a",
      },
      error: {
        main: "#f3002c",
        light: "#fa8996",
        dark: "#c30013",
        contrastText: "#ffffff",
      },
      background: {
        default: "#fff",
        paper: "#F3F5F9",
      },
      action: {
        active: '#212b3a'
      }
    },
    typography: {
      fontFamily: "cera-pro",
      allVariants: {
        color: "#212b3a",
      },
    },
    components: {
      MuiFilledInput: {
        defaultProps: {
          disableUnderline: true,
        },
        styleOverrides: {
          root: {
            borderRadius: "4px",
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            borderRadius: "6px",
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            borderRadius: "6px",
          },
        },
      },
    },
  });

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  const theme = darkMode ? darkTheme : lightTheme;

  return (
    <ThemeProvider theme={theme}>
      <ThemeContext.Provider value={{ darkMode, toggleDarkMode }}>
        {children}
      </ThemeContext.Provider>
    </ThemeProvider>
  );
};


export const useThemeMode = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error("useTheme must be used within a ThemeProvider");
  }
  return context;
};
