import { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import { getTagsList } from "@/api/list";

const useTagsList = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const getTagsData = async () => {
    setLoading(true);
    setError(null);
    try {
      const accessToken = await getAccessTokenSilently();
      const { data, error } = await getTagsList(accessToken);
      if (error) {
        setError(error.message);
      } else {
        return data;
      }
    } catch (err) {
      setError((err as Error).message);
    } finally {
      setLoading(false);
    }
  };

  return { getTagsData, loading, error };
};

export default useTagsList;
