import React from "react";
import { CircularProgress, Grid, Typography } from "@mui/material";

interface FetchDataProps {
  message: string;
}

const FetchData: React.FC<FetchDataProps> = ({ message }) => {
  return (
    <div
      style={{
        display: "flex",
        flexGrow: "1",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Grid container spacing={3} direction="column" alignItems="center">
        <Grid item xs={12}>
          <Typography>{message}</Typography>
        </Grid>
        <Grid item xs={12}>
          <CircularProgress />
        </Grid>
      </Grid>
    </div>
  );
};

export default FetchData;
