import { AxiosRequestConfig } from "axios";

import { ApiError } from "@/interfaces/api";
import { Audience } from "@/types";

import { fetchData } from "@/api/utils";

type FavouriteAudienceApiResponse = {
  data: Array<Audience>;
  error: ApiError | null;
};

const favouriteAudience = async (
  accessToken: string,
  audienceName: string,
  value: any
): Promise<FavouriteAudienceApiResponse> => {
  const url = `${process.env.REACT_APP_API_URL}/audience/favourite?audience_name=${audienceName}&value=${value}`;

  const config: AxiosRequestConfig = {
    method: "POST",
    url,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  return fetchData(config);
};

export default favouriteAudience;
