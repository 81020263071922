import { InsightCard } from "@/components/atoms";
import { Box, Grid, Typography } from "@mui/material";
import { PenPortrait as PenPortraitType, Question } from "@/types";
import React, { useEffect, useState } from "react";

import { PenPortraitMap } from "@/components/molecules";
import { NumericFormat } from "react-number-format";

interface PenPortraitProps {
  data: PenPortraitType;
  mapStyle: string;
}

const PenPortrait: React.FC<PenPortraitProps> = ({
  data,
  mapStyle,
}) => {
  const [questions, setQuestions] = useState<Array<Question>>([]);

  useEffect(() => {
    setQuestions(
      data.questions.slice(0, 6).map((question) => ({
        id: question.id,
        question: question.question,
      }))
    );
  }, [data]);

  const handleQuestionChange = (newQuestion: string, questionIndex: number) => {
    const question = data.questions.find(
      (question) => question.question === newQuestion
    );
    if (question) {
      setQuestions((prev) => {
        const newQuestions = [...prev];
        newQuestions[questionIndex] = {
          id: question.id,
          question: question.question,
        };
        return newQuestions;
      });
    }
  };

  return (
    <>
      <Grid
        container
        item
        xs={12}
        style={{
          marginTop: 10,
        }}
      >
        <Grid container item xs={9}>
          <Grid
            item
            xs={12}
            style={{
              borderTop: "1px solid #e0e0e0",
              borderBottom: "1px solid #e0e0e0",
              marginRight: 12,
            }}
          >
            <Grid container item xs={12} justifyContent="space-between">
              {["age", "income", "lifestage"].map((demographic, index) => (
                <Grid item xs={3} key={index}>
                  <Box padding={3}>
                    <Typography
                      variant="h6"
                      style={{ fontWeight: "bold" }}
                      color="primary"
                    >
                      {demographic.charAt(0).toUpperCase() +
                        demographic.slice(1)}
                    </Typography>
                    <Typography variant="body1">
                      {data.demographics[demographic]}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
            <Grid container justifyContent="space-between">
              {["postcodes", "sectors", "households"].map((sizeType, index) => (
                <Grid item xs={3} key={index}>
                  <Box padding={3}>
                    <Typography
                      variant="h6"
                      style={{ fontWeight: "bold" }}
                      color="primary"
                    >
                      {sizeType.charAt(0).toUpperCase() + sizeType.slice(1)}
                    </Typography>
                    <Typography variant="body1">
                      <NumericFormat
                        value={data.size[sizeType]}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Grid>
          {data.description && (
            <Grid item xs={12}>
              <Typography
                variant="body1"
                style={{ marginTop: 12, marginRight: 12 }}
              >
                {data.description}
              </Typography>
            </Grid>
          )}
        </Grid>
        <Grid item xs={3}>
          <div className="small-map-area">
            <PenPortraitMap
              areaScores={data.location.areaScores}
              mapStyle={mapStyle}
            />
          </div>
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={2}>
        {questions.map((question, index) => {
          const penPortraitInsight = data.insight.find(
            (insight) => insight.question === question.question
          );
          if (!penPortraitInsight) return null;

          return (
            <Grid item xs={4} key={index}>
              <InsightCard
                question={question}
                selectedQuestions={questions}
                questions={data.questions}
                variables={penPortraitInsight.variables}
                group={penPortraitInsight.tag_group}
                type={penPortraitInsight.tag_type}
                onQuestionChange={(question) =>
                  handleQuestionChange(question, index)
                }
              />
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default PenPortrait;
