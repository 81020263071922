import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

const useDataDialogStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogTitle: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    bulletParagraph: {
      marginBottom: "12px",
    },
    bulletText: {
      paddingLeft: "24px",
    },
    rowDiv: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      marginLeft: "24px",
    },
    marginText: {
      marginRight: 4,
    },
    marginBoldText: {
      marginRight: 4,
      fontWeight: "bold",
    },
    sectionHeader: {
      fontWeight: "bold",
    },
    paper: {
      minWidth: "70%",
      height: "60vh",
    },
    tableRow: {
      "&:first-child": {
        backgroundColor: "rgba(0, 0, 0, 0.1)",
      },
    },
    tableFooter: {
      paddingTop: "6px",
      paddingBottom: "2em",
    },
  })
);

export default useDataDialogStyles;
