import {OperatorTextField} from "@/components/atoms/QueryGroupSelection/QueryGroupSelection.styles";
import {
  Audience,
  AudienceSize,
  Tag,
  ValidationResult,
  Variable,
  VariableGroup,
} from "@/types";
import {
  Button,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Typography,
} from "@mui/material";
import {
  ConfigureBuild,
  ErrorPanel,
  FetchData,
  QueryGroupSelection,
} from "@/components/atoms";
import React, { useEffect, useState } from "react";

import { AudienceValidation } from "@/components/molecules";
import { Close } from "@mui/icons-material";
import { operators } from "@/constants";
import { buildAudience } from "@/api";
import { getThreshold } from "./utils";
import { useAuth0 } from "@auth0/auth0-react";
import useBuildStyles from "./Build.styles";
import useTagsList from "@/hooks/api/use-tags-list";
import { tagCategories } from "@/constants";

interface BuildProps {
  audiences: Array<Audience>;
  globalAudienceName: string;
  globalAudienceSize: AudienceSize | null;
  globalQueryGroups: Array<VariableGroup>;
  globalMainOperator: string;
  globalGeo: string;
  globalMode: string;
  globalTags: Array<Tag>;
  onAudienceUpdate: (audienceSize: AudienceSize | null) => void;
  onAudienceSizeUpdate: (audienceSize: AudienceSize) => void;
  onAudiencesUpdate: (audiences: Array<Audience>) => void;
  onAudienceNameUpdate: (name: string) => void;
  onGeoUpdate: (geo: string) => void;
  onGroupAdd: () => void;
  onGroupDelete: (groupId: number) => void;
  onOperatorUpdate: (groupId: number, operator: string) => void;
  onMainOperatorUpdate: (operator: string) => void;
  onModeUpdate: (mode: string) => void;
  onSelectionUpdate: (groupId: number, variables: Variable[]) => void;
  onTagsUpdate: (tags: Tag[]) => void;
}

const Build: React.FC<BuildProps> = ({
  audiences,
  globalAudienceName,
  globalQueryGroups,
  globalAudienceSize,
  globalMainOperator,
  globalGeo,
  globalMode,
  globalTags,
  onGroupAdd,
  onGroupDelete,
  onOperatorUpdate,
  onMainOperatorUpdate,
  onGeoUpdate,
  onModeUpdate,
  onAudienceUpdate,
  onAudiencesUpdate,
  onAudienceNameUpdate,
  onSelectionUpdate,
  onTagsUpdate,
}) => {
  const styles = useBuildStyles();
  const { getAccessTokenSilently } = useAuth0();
  const {
    getTagsData,
    loading: tagsListLoading,
    error: tagsListError,
  } = useTagsList();

  const [tags, setTags] = useState<Array<Tag>>([]);
  const [queryGroups, setQueryGroups] = useState<Array<VariableGroup>>([]);
  const [mainOperator, setMainOperator] = useState<string>(globalMainOperator);
  const [geo, setGeo] = useState<string>(globalGeo);
  const [mode, setMode] = useState<string>(globalMode);
  const [audienceSize, setAudienceSize] = useState<AudienceSize | null>(globalAudienceSize);
  const [validLoading, setValidLoading] = useState<boolean>(false);
  const [hasValidated, setValidated] = useState<boolean>(false);
  const [validationResult, setValidationResult] = useState<ValidationResult | null>(null);
  const [permissions, setPermissions] = useState<string[]>([]);
  const [categories, setCategories] = useState<string[]>(tagCategories);

  const disabled = queryGroups.length === 0 || queryGroups[0].variables.length === 0;

  useEffect(() => {
    setTags(globalTags);
    setQueryGroups(globalQueryGroups);
    setMainOperator(globalMainOperator);
    setGeo(globalGeo);
    setMode(globalMode);
    setAudienceSize(globalAudienceSize);
  }, [
    globalTags,
    globalQueryGroups,
    globalMainOperator,
    globalGeo,
    globalMode,
    globalAudienceSize,
  ]);

  useEffect(() => {
    const fetchData = async () => {
      const data = await getTagsData();
      if (data) {
        onTagsUpdate(data);
      }
    };

    if (globalTags.length > 0) {
      setTags(globalTags);
    } else {
      fetchData();
    }
  }, [globalTags]);

  useEffect(() => {
    const fetchPermissions = async () => {
      try {
        const token = await getAccessTokenSilently();
        const decodedToken: any = JSON.parse(atob(token.split(".")[1]));
        setPermissions(decodedToken[`https://${process.env.REACT_APP_AUTH0_DOMAIN}/permissions`] || []
        );
      } catch (error) {
        console.error("Error fetching permissions:", error);
      }
    };
    fetchPermissions();
  }, [getAccessTokenSilently]);

  useEffect(() => {
    if (permissions.includes('postcode:snoop')) setCategories((prevState) => [...prevState, 'Open Banking']);
    if (permissions.includes('postcode:mastercard')) setCategories((prevState) => [...prevState, 'Spend Categories'])
  }, [permissions]);

  const handleUpdateAudienceName = (
    audienceName: string,
    validationResult?: ValidationResult
  ) => {
    if (validationResult) setValidationResult(validationResult);
    onAudienceNameUpdate(audienceName);
  };

  const handleOperatorSelect = (operator: string) => onMainOperatorUpdate(operator);

  const handleValidate = async () => {
    const token = await getAccessTokenSilently();

    if (!validLoading) {
      onAudienceUpdate(null);
      setValidLoading(true);

      const threshold = getThreshold(queryGroups, mode);
      const queries = queryGroups
        .filter((queryGroup) => queryGroup.variables.length > 0)
        .map((queryGroup) => {
          const tagIds = queryGroup.variables.map(
            (variable) => variable.tag_id
          );
          return `${queryGroup.operator}(${tagIds.join()})`;
        });

      const url = `${
        process.env.REACT_APP_API_URL
      }/campaign/build?query=${queries.join(
        "|"
      )}&operator=${mainOperator}&threshold=${threshold}&geo=${geo}`;

      const { data, error } = await buildAudience(token, url);

      if (data) {
        onAudienceUpdate(data);
        setValidLoading(false);
        setValidated(true);
      }

      if (error) {
        console.error("Error validating audience:", error);
        setValidLoading(false);
        setValidated(true);
      }
    }
  };

  const handleSelectionUpdate = (groupId: number, variables: Array<Variable>) =>
    onSelectionUpdate(groupId, variables);

  if (tagsListLoading) return <FetchData message="Loading variables" />;
  if (tagsListError)
    return <ErrorPanel error={tagsListError} errorMessage={tagsListError} />;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} mb={1}>
        <Typography gutterBottom variant="h6" className={styles.cardHeader}>
          Build
        </Typography>
        <Typography variant="body1" gutterBottom>
          Build your audience by selecting the variables for your campaign. For
          more complex queries, create multiple query groups.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {queryGroups.map((variableGroup, idx) => (
          <Grid
            container
            spacing={1}
            item
            xs={12}
            alignItems="center"
            key={`group-${idx}`}
            ml={2}
          >
            <QueryGroupSelection
              categories={categories}
              groupId={variableGroup.id}
              tags={tags}
              groupSelection={variableGroup.variables}
              groupOperator={variableGroup.operator}
              onSelectionUpdate={handleSelectionUpdate}
              onOperatorUpdate={onOperatorUpdate}
            />
            {idx > 0 && (
              <Grid item xs={1}>
                <IconButton onClick={() => onGroupDelete(variableGroup.id)}>
                  <Close />
                </IconButton>
              </Grid>
            )}
            {queryGroups.length > 1 && idx + 1 !== queryGroups.length && (
              <Grid
                container
                spacing={3}
                item
                xs={11}
                className={styles.rowGrid}
                alignItems="center"
                justifyContent="center"
              >
                <Grid item xs={4.8}>
                  <Divider />
                </Grid>
                <Grid item xs={2}>
                  <OperatorTextField
                    variant="outlined"
                    size="small"
                    color={"primary"}
                    fullWidth
                    select
                    value={mainOperator}
                    onChange={(e) => onMainOperatorUpdate(e.target.value)}
                  >
                    {operators.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </OperatorTextField>
                </Grid>
                <Grid item xs={4.8}>
                  <Divider />
                </Grid>
              </Grid>
            )}
          </Grid>
        ))}
      </Grid>
      <Grid item xs={12}>
        <Button variant="outlined" color="primary" onClick={onGroupAdd}>
          Add Another
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <ConfigureBuild
        globalGeo={geo}
        globalMode={mode}
        onGeoUpdate={onGeoUpdate}
        onModeUpdate={onModeUpdate}
      />
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Typography gutterBottom variant="h6" className={styles.title}>
          Audience
        </Typography>
        <AudienceValidation
          audiences={audiences}
          audienceSize={audienceSize}
          hasValidated={hasValidated}
          geo={geo}
          globalAudienceName={globalAudienceName}
          mainOperator={mainOperator}
          mode={mode}
          onDataCheck={handleValidate}
          queryGroups={queryGroups}
          onUpdateAudiences={onAudiencesUpdate}
          onUpdateAudienceName={handleUpdateAudienceName}
          validDisabled={disabled}
          validLoading={validLoading}
          validationResult={validationResult}
        />
      </Grid>
    </Grid>
  );
};

export default Build;
