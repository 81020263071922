// @ts-nocheck

import React from "react";
import { Chart } from "react-chartjs-2";
import { Box, alpha } from "@mui/material";

import {
  Chart as ChartJS,
  Filler,
  Legend,
  LineElement,
  PointElement,
  Tooltip,
} from "chart.js";
import CustomRadarController from "./RadarController";
import CustomRadarLinearScale from "./RadarLinearScale";

ChartJS.register(PointElement, LineElement, Filler, Tooltip, Legend);

ChartJS.register(CustomRadarLinearScale, CustomRadarController);

type RadarChartProps = {
  values: number[][];
  labels: string[];
  labelColors?: string[];
  height?: number;
  width?: number;
  padding?: number;
  names?: string[];
};

function multiLine(str) {
  const parts = str.split(" ");
  if (parts.length === 2) {
    return str.split(" ");
  } else {
    return str.replace(/.{10}\S*\s+/g, "$&@").split(/\s+@/);
  }
}

const COLORS = [
  { main: "#4ed4eb", background: alpha("#4ed4eb", 0.4) },
  { main: "#7f5fff", background: alpha("#7f5fff", 0.4) },
  { main: "#F39879", background: alpha("#F39879", 0.4) },
];

const RadarChart: React.FC<RadarChartProps> = ({
  values,
  labels,
  labelColors,
  height,
  width,
  padding,
  names,
}) => {
  return (
    <Box width={width || "100%"} height={height || 450}>
      <Chart
        type="radar"
        options={{
          animation: false,
          responsive: true,
          maintainAspectRatio: false,
          layout: {
            padding: padding || 4,
          },
          scales: {
            r: {
              type: "derivedRadialLinearScale",
              suggestedMin: 0,
              suggestedMax: 1,
              ticks: {
                display: false,
              },
              grid: {
                color: "rgba(132, 138, 146, 1)",
                circular: true,
              },
              angleLines: {
                color: "rgba(132, 138, 146, 1)",
              },
              pointLabels: {
                color: labelColors || "rgba(255, 255, 255, 1)",
              },
            },
          },
          plugins: {
            legend: {
              display: !!names,
              position: "bottom",
              labels: {
                color: "white",
                boxWidth: 32,
              },
            },
            tooltip: {
              backgroundColor: "#2C3644",
              callbacks: {
                label: function (context) {
                  return ` ${(context.parsed.r * 100).toFixed(0)}`;
                },
              },
            },
          },
        }}
        data={{
          labels: labels.map((x) => multiLine(x)),
          datasets: values.map((data, dataIdx) => ({
            data: data,
            label: names ? names[dataIdx] : "",
            backgroundColor: COLORS[dataIdx].background,
            pointBackgroundColor: COLORS[dataIdx].main,
            pointBackdropPadding: 2,
            pointRadius: 3,
            pointRotation: 90,
            borderColor: COLORS[dataIdx].main,
            borderWidth: 2,
          })),
        }}
      />
    </Box>
  );
};

export default RadarChart;
