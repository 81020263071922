import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

const useMapInsightStyles = makeStyles((theme: Theme) =>
  createStyles({
    insightBox: {
      height: "200px",
    },
    demogBox: {
      height: "360px",
    },
    insightTitle: {
      fontSize: "14px",
      fontFamily: "cera-pro-bold",
      textTransform: "uppercase",
    },
    insightSection: {
      fontSize: "12px",
      fontFamily: "cera-pro-bold",
      marginBottom: "10px",
    },
    insightText: {
      fontSize: "12px",
    },
    insightTextBold: {
      fontSize: "12px",
      fontFamily: "cera-pro-bold",
    },
    rowDiv: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    icon: {
      margin: "8px",
    },
    bigIcon: {
      width: "48px",
      height: "48px",
      margin: "8px",
    },
  })
);

export default useMapInsightStyles;
