import { CardHeader } from "@mui/material";
import { createStyles, makeStyles, withStyles } from "@mui/styles";

export const useTableViewStyles = makeStyles(() =>
  createStyles({
    table: {
      maxHeight: 440,
      // margin: "0px 8px 6px 8px",
    },
    attitudesTable: {
      maxHeight: 800,
      // margin: "0px 8px 6px 8px",
    },
    tableHeader: {
      fontSize: 12,
      textTransform: "uppercase",
      letterSpacing: 0.5,
    },
    title: {
      marginTop: "1em",
    },
    text: {
      fontSize: 12,
    },
    textPositive: {
      fontSize: 12,
      color: "#00B0CC",
    },
    textNegative: {
      fontSize: 12,
      color: "#eb6f09",
    },
    dataCard: {
      borderRadius: "10px",
      boxShadow: "0px 10px 60px -5px #01214E10",
    },
    graphTitle: {
      display: "flex",
      alignItems: "center",
    },
  })
);

export const StyledCardHeader = withStyles({
  root: {
    padding: "10px 16px 10px 16px",
  },
  action: {
    margin: 0,
  },
})(CardHeader);
