import React from "react";
import { Typography } from "@mui/material";
import { ValidationResult } from "@/types";
import useValidationStyles from "../Validation.styles";

interface SummaryProps {
  type: "schema" | "data";
  results: ValidationResult;
}

const Summary: React.FC<SummaryProps> = ({ type, results }) => {
  const styles = useValidationStyles();
  const text = type === "schema" ? "Valid Schema" : "Valid Data";
  const isValid = results[type];

  return (
    <div>
      <div className={styles.rowDiv}>
        <Typography variant="body2" className={styles.marginText}>
          {text}
        </Typography>
        <Typography
          variant="body2"
          className={`${styles.capText} ${
            isValid ? styles.trueText : styles.falseText
          }`}
        >
          {results[type]}
        </Typography>
      </div>

      {!isValid && results.reason && (
        <div className={styles.rowDiv}>
          <Typography variant="body2" className={styles.falseText}>
            {results.reason}
          </Typography>
        </div>
      )}
    </div>
  );
};

export default Summary;
