import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import {lighten} from "@mui/material/styles";

const useUploadStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonIcon: {
      marginRight: "8px",
    },
    fileInput: {
      padding: "8px",
      backgroundColor: lighten(theme.palette.background.paper, 0.14),
    },
    fileInputGroup: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
    end: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-end",
    },
    fileText: {
      display: "flex",
      flexDirection: "row",
    },
    marginText: {
      paddingLeft: "6px",
    },
    headerSections: {
      marginLeft: "4px",
    },
    textBox: {
      borderRadius: "4px",
    },
    tableHeader: {
      fontFamily: 'cera-pro-bold'
    },
  })
);

export default useUploadStyles;
