import { ThemeProviderWrapper } from "@/context/theme-context";
import { CssBaseline } from "@mui/material";

import React, { PropsWithChildren } from "react";

interface ThemeContainerProps {
  children: React.ReactNode;
}

const ThemeContainer = ({
  children,
}: PropsWithChildren<ThemeContainerProps>): JSX.Element | null => {
  return (
    <ThemeProviderWrapper>
      <CssBaseline />
      {children}
    </ThemeProviderWrapper>
  );
};

export default ThemeContainer;
