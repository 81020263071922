import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

const useValidationStyles = makeStyles((theme: Theme) =>
  createStyles({
    marginText: {
      marginRight: 4,
    },
    capText: {
      marginRight: 4,
      textTransform: "capitalize",
    },
    falseText: {
      color: theme.palette.error.main,
    },
    trueText: {
      color: theme.palette.success.main,
    },
    validateButton: {
      width: "100%",
    },
    rowDiv: {
      display: "flex",
      flexDirection: "row",
    },
    rowDivSpacing: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-evenly",
      marginBottom: 12,
    },
    wrapper: {
      position: "relative",
    },
    buttonProgress: {
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },
  })
);

export default useValidationStyles;
