import { createStyles, makeStyles } from "@mui/styles";

const useMapGradientStyles = makeStyles(() =>
  createStyles({
    gradientBox: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    gradientBar: {
      margin: "4px",
      height: "120px",
      width: "10px",
      borderRadius: "4px",
      border: "1px solid #BDBFBF",
      backgroundImage:
        "linear-gradient(0deg, #141938 0%, #223b89 14%, #316ba7 33%, #2b96c5 58%, #80ffff 80%)",
    },
    steps: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      height: "120px",
    },
    stepText: {
      fontSize: "10px",
    },
  })
);

export default useMapGradientStyles;
