import MainLayout from "@/components/layouts/MainLayout";
import {appFlows, interestIcon, modeIcon} from "@/constants";
import {
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  CardActions,
  Button,
  Typography, SvgIcon,
} from "@mui/material";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';


import Grid from "@mui/system/Unstable_Grid";
import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useHistory } from "react-router-dom";


const Home: React.FC = () => {
  const history = useHistory();
  const { user } = useAuth0();

  if (!user) return null;

  const handleCardClick = (id: string) => () => history.push(`/${id}`);

  return (
    <MainLayout>
      <Grid
        container
        spacing={6}
        justifyContent="center"
      >
        <Grid xs={12} sx={{ mb: 4 }}>
          <Typography sx={{fontSize: 162, fontFamily: 'cera-pro-bold', textAlign: 'center'}}>—CONNECT—</Typography>
          <Typography sx={{fontSize: 28, textAlign: 'center'}}>How would you like to define your Audience?</Typography>
        </Grid>
        {appFlows.map(({ id, name, subtitle }) => {
          const IconComponent = modeIcon(id);
          return (
            <Grid key={id}>
              <Card sx={{ width: 520 }}>
                {/*<CardActionArea onClick={handleCardClick(id)}>*/}
                  <CardHeader
                    avatar={<SvgIcon
                      component={modeIcon(id).ReactComponent}
                      inheritViewBox
                      sx={{ fontSize: "50px" }}
                    />}
                    // avatar={<IconComponent fontSize='large' color='primary'/>}
                    title={name}
                    titleTypographyProps={{ variant: "h5" }}
                  />
                  <CardContent>
                    <Typography>{subtitle}</Typography>
                  </CardContent>
                  <CardActions sx={{ justifyContent: 'flex-end' }}>
                    <Button
                      onClick={handleCardClick(id)}
                      endIcon={<ChevronRightIcon />}
                    >
                      Select
                    </Button>
                  </CardActions>
                {/*</CardActionArea>*/}
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </MainLayout>
  );
};

export default Home;
