import ProtectedRoute from "@/components/containers/Route/Protected";
import ThemeContainer from "@/components/containers/Theme";
import {
  Audience,
  NotFound,
  Unauthorized,
  Home,
  Callback,
  Customer,
} from "@/components/pages";
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

function App() {
  return (
    <ThemeContainer>
      <Switch>
        <ProtectedRoute path="/" exact component={Home} />
        <ProtectedRoute path="/audience" exact component={Audience} />
        <ProtectedRoute path="/customer" exact component={Customer} />
        <Route path="/callback" component={Callback} />
        <Route path="*" component={NotFound} />
        {/* If the user is rejected by log-in, redirect them to the unauthorized page */}
        <Route path="/unauthorized" component={Unauthorized} />

        {/* Redirect /login to AppWrapper to delegate auth decisions */}
        <Redirect from="/login" to="/" />
      </Switch>
    </ThemeContainer>
  );
}

export default App;
