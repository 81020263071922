import { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { customerDiscover } from "@/api";
import { FileInfo } from "@/types";

const useCustomerDiscover = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const getCustomerDiscoverData = async (fileInfo: FileInfo | null) => {
    setLoading(true);
    setError(null);
    try {
      const accessToken = await getAccessTokenSilently();
      const { data, error } = await customerDiscover(
        accessToken,
        fileInfo?.hasHeader || true,
        fileInfo?.header || "",
        fileInfo?.delimiter || ",",
        fileInfo?.file
      );
      if (error) {
        setError(error.message);
      } else {
        return data;
      }
    } catch (err) {
      setError((err as Error).message);
    } finally {
      setLoading(false);
    }
  };

  return { getCustomerDiscoverData, loading, error };
};

export default useCustomerDiscover;
