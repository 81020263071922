import { ApiError } from "@/interfaces/api";
import { AudienceSize } from "@/types";
import { AxiosRequestConfig } from "axios";

import { fetchData } from "@/api/utils";

interface BuildAudienceApiResponse {
  data: AudienceSize;
  error: ApiError | null;
}

const buildAudience = async (
  accessToken: string,
  url: string
): Promise<BuildAudienceApiResponse> => {
  const config: AxiosRequestConfig = {
    method: "GET",
    url,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  return fetchData(config);
};

export default buildAudience;
