import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

const useMapStyles = makeStyles((theme: Theme) =>
  createStyles({
    mapArea: {
      display: "block",
      position: "relative",
      paddingBottom: "1em",
    },
    mapContainer: {
      height: "560px",
    },
    sidebar: {
      backgroundColor: "#232D3A",
      opacity: 0.85,
      color: "#fff",
      // backgroundColor: '#5e677c',
      padding: "6px 12px",
      zIndex: "1",
      position: "absolute",
      top: 0,
      left: 0,
      margin: "12px",
      borderRadius: "4px",
      width: "170px",
    },
    scorebar: {
      backgroundColor: "#232D3A",
      opacity: 0.85,
      color: "#fff",
      // backgroundColor: '#5e677c',
      padding: "12px 12px",
      zIndex: "1",
      position: "absolute",
      top: 0,
      right: 0,
      margin: "12px",
      borderRadius: "4px",
      width: "450px",
      height: "536px",
    },
    gradient: {
      zIndex: "1",
      position: "absolute",
      bottom: 0,
      left: 0,
      marginBottom: "115px",
      marginLeft: "6px",
    },
    switch: {
      zIndex: "1",
      position: "absolute",
      bottom: 0,
      left: 0,
      marginBottom: "35px",
      marginLeft: "60px",
    },
    rowDiv: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    title: {
      textTransform: "uppercase",
      fontFamily: "cera-pro-bold",
      textAlign: "center",
      marginTop: "8px",
      marginBottom: "8px",
    },
    overlayText: {
      fontSize: "14px",
    },
    boldText: {
      fontSize: "14px",
      fontFamily: "cera-pro-bold",
    },
    bar: {
      fontSize: "14px",
      borderRadius: "5px",
      height: "10px",
      // backgroundColor: '#6FD2E2'
    },
  })
);

export default useMapStyles;
