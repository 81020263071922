import { Card, Grid, IconButton } from "@mui/material";
import { StyledCardHeader, useTableViewStyles } from "./TableView.styles";

import { CsvBuilder } from "filefy";
import DataTable from "@/components/atoms/DataTable";
import { GetApp } from "@mui/icons-material";
import { InsightResult } from "@/types";
import React from "react";

interface TableViewProps {
  data: InsightResult[];
  groups: string[];
  category: string;
}

const TableView: React.FC<TableViewProps> = ({ category, groups, data }) => {
  const styles = useTableViewStyles();

  const downloadTable = (
    data: {
      score: number;
      rate: number;
      tag_name: string;
      tag_group: string;
      zscore: string | number;
      id: number;
    }[]
  ) => {
    const tag_group = data[0].tag_group;
    const rows = data.map((x) => [x.tag_name, x.rate, x.score, x.zscore]);
    const builder = new CsvBuilder(`${tag_group}.csv`);
    builder
      .setDelimeter(",")
      .setColumns(["Name", "Penetration", "Index", "Z-Score"])
      .addRows(rows.map((row) => row.map((value) => String(value)))) // Convert numbers to strings
      .exportFile();
  };

  return (
    <>
      {groups.map((group, idx) => {
        const subData = data.filter((x) => x.tag_group === group);
        const tabData = subData.map((x) => ({
          id: x.tag_id,
          tag_name: x.tag_name,
          score: Math.ceil(x.score),
          rate: x.rate,
          zscore:
            Number(x.zscore?.toFixed(2) ?? 0) < 10
              ? x.zscore?.toFixed(2) ?? "0"
              : Math.ceil(x.zscore ?? 0),
          tag_group: x.tag_group,
        }));
        return (
          <Grid item xs={6} key={`insight-${idx}`}>
            <StyledCardHeader
              title={group}
              titleTypographyProps={{ variant: "h6" }}
              action={
                <IconButton
                  color="inherit"
                  onClick={() => downloadTable(tabData)}
                  size="small"
                  edge="end"
                >
                  <GetApp />
                </IconButton>
              }
              className={styles.graphTitle}
            />
            <Card elevation={1} className={styles.dataCard}>
              <DataTable group={group} data={tabData} category={category} />
            </Card>
          </Grid>
        );
      })}
    </>
  );
};

export default TableView;
