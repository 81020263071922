import React from "react";
import { CsvBuilder } from "filefy";
import { Grid } from "@mui/material";

import { InsightResult } from "@/types";

import { useGraphViewStyles } from "./GraphView.styles";
import {getAverage, getMedian} from "@/utils/common";
import {GraphViewCard} from "./GraphViewCard";

interface GraphViewProps {
  tab: string;
  data: InsightResult[];
  groups: string[];
  scoreView?: string;
}

const GraphView: React.FC<GraphViewProps> = ({ tab, groups, data, scoreView }) => {

  const downloadTable = (
    data: { score: number; tag_name: string; tag_group: string; id: number }[]
  ) => {
    const tag_group = data[0].tag_group;
    const rows = data.map((x) => [x.tag_name, x.score]);
    const builder = new CsvBuilder(`${tag_group}.csv`);
    builder
      .setDelimeter(",")
      .setColumns(["Name", "Index"])
      .addRows(rows.map((row) => row.map((value) => String(value)))) // Convert numbers to strings
      .exportFile();
  };

  return (
    <>
      {groups.map((group, idx) => {
        const subData = data.filter((x) => x.tag_group === group);
        const tabData = subData.map((x) => ({
          id: x.tag_id,
          score:
            scoreView && scoreView === "index"
              ? Math.ceil(x.score)
              : Math.ceil(x.zscore ? x.zscore : x.score),
          tag_name: x.tag_name,
          tag_group: x.tag_group,
        }));

        const groupScore = tab === 'Interests' ?
          Math.ceil(subData.filter((x) => x.tag_name === group)[0][scoreView && scoreView === "index" ? 'score' : 'zscore'] || 0)
          : ['Demographics'].includes(tab) ? null
            : Math.ceil(getAverage(subData.slice(0, 10).map((x) => scoreView && scoreView === "index" ? x.score : x.zscore ? x.zscore : x.score)));

        return (
          <Grid item xs={6} key={`insight-${idx}`}>
            <GraphViewCard
              name={group}
              score={groupScore}
              data={tabData}
              scoreView={scoreView}
              startExpanded={idx < 2}
            />
          </Grid>
        );
      })}
    </>
  );
};

export default GraphView;
