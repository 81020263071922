import { ErrorPanel, FetchData } from "@/components/atoms";

import { ExportDialog, ExportOptions } from "@/components/molecules";
import useCustomerLookalikes from "@/components/organisms/Customer/Lookalikes/use-customer-lookalikes";
import { exportChannels } from "@/constants";

import { FileInfo, Tag } from "@/types";
import { useAuth0 } from "@auth0/auth0-react";
import {Button, Grid, MenuItem, TextField, Typography, Box} from "@mui/material";
import React, { useEffect, useState } from "react";

interface LookalikesProps {
  fileInfo: FileInfo | null;
  segments: string[];
  onFileDownload: () => void;
}

const Lookalikes: React.FC<LookalikesProps> = ({
  fileInfo,
  segments,
  onFileDownload,
}) => {
  const {
    exportLookalikes,
    loading: exportLoading,
    error: exportError,
  } = useCustomerLookalikes();

  const { getAccessTokenSilently } = useAuth0();

  const [open, setOpen] = useState(false);
  const [segment, setSegment] = useState(segments[0]);
  const [permissions, setPermissions] = useState<string[]>([]);
  const [selectedExportOption, setSelectedExportOption] = useState("");
  const allowExport = permissions.includes("postcode:export");

  useEffect(() => {
    const fetchPermissions = async () => {
      try {
        const token = await getAccessTokenSilently();
        const decodedToken: any = JSON.parse(atob(token.split(".")[1]));
        setPermissions(
          decodedToken[
            `https://${process.env.REACT_APP_AUTH0_DOMAIN}/permissions`
          ] || []
        );
      } catch (error) {
        console.error("Error fetching permissions:", error);
      }
    };

    fetchPermissions();
  }, [getAccessTokenSilently]);

  const handleOnConfirm = async () => {
    if (fileInfo) {
      const data = await exportLookalikes(
        fileInfo,
        selectedExportOption,
        segment
      );

      if (data) {
        window.open(data.url, "_blank");
        onFileDownload();
        handleCheckClose();
      }
    }
  };

  const handleCheckClose = () => setOpen(false);

  const handleSegmentChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setSegment(e.target.value);


  const handleStartExport = (selectedOption: string) => {
    setSelectedExportOption(selectedOption);
    setOpen(true);
  };


  if (exportLoading) return <FetchData message="Preparing data for download" />;

  if (exportError) {
    return (
      <ErrorPanel
        error={exportError || ""}
        errorMessage={exportError ?? ""}
      />
    );
  }
  return (
    <Grid container spacing={3} alignItems="center" alignContent="center">
      <Grid item xs={12}>
        <Typography
          gutterBottom
          variant="h6"
          fontWeight="bold"
          textTransform="uppercase"
        >
          Lookalikes
        </Typography>
        <Typography variant="body1" gutterBottom>
          {segment
            ? "Select a segment to calculate lookalike postcodes based on the Interests of your customers."
            : "Identify lookalike postcodes based on the Interests of your customers."}
        </Typography>
      </Grid>
      {segment && (
        <Grid item xs={3}>
          <TextField
            fullWidth
            id="segment-select"
            label="Select a segment"
            variant="filled"
            select
            value={segment}
            onChange={handleSegmentChange}
          >
            {segments.sort().map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      )}
      <Grid item container xs={12} spacing={2} style={{ marginTop: 1 }}>
        <Grid item xs={12}>
          <Typography variant="body1" gutterBottom>
            Select the platform you wish to export data for.
          </Typography>
        </Grid>
        {exportChannels.map((channel, channelIdx) => (
          <Grid item container spacing={1} xs={12} key={channelIdx}>
            <Grid item>
            <Typography variant="h6">{channel.name}</Typography>
            </Grid>
            {channel.options.map((option, optionIdx) => (
              <Grid key={optionIdx} xs={12} item alignItems={"center"}>
                <Box display="flex" flexDirection="row" alignItems="center">
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    disabled={!allowExport}
                    sx={{ minWidth: 200, mr: 4}}
                    onClick={() => handleStartExport(option.export_type)}
                  >
                    {option.name}
                  </Button>
                  <Typography variant="body2">
                    {option.description}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        ))}

        <Grid item xs={12} style={{ marginTop: "12px" }}>
          <Typography variant="caption" color="textSecondary">
            Starcount attributes are onboarded in Adsquare and DV360, The
            Trade Desk, App Nexus and Amobee via Eyeota.
          </Typography>
        </Grid>
      </Grid>

      <ExportDialog
        open={open}
        onConfirm={handleOnConfirm}
        onClose={handleCheckClose}
      />
    </Grid>
  );
};

export default Lookalikes;
