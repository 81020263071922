import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  AutocompleteRenderGroupParams,
  Chip,
  Grid,
  ListSubheader,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from "@mui/material";

import { Tag } from "@/types";

import useStyles from "./VariableSelection.styles";

interface VariableSelectionProps {
  groupId: number;
  categories: Array<string>;
  groupSelection: Array<Tag>;
  tags: Array<Tag>;
  onSelectionUpdate: (groupId: number, selection: any[]) => void;
}

const VariableSelection: React.FC<VariableSelectionProps> = ({
  groupId,
                                                               categories,
  tags,
  groupSelection,
  onSelectionUpdate,
}) => {
  const styles = useStyles();

  const [category, setCategory] = useState("Interests");
  const [selection, setSelection] = useState<Tag[]>([]);

  useEffect(() => {
    setSelection(groupSelection);
  }, [groupSelection]);

  const handleCategorySelect = (e: React.ChangeEvent<HTMLInputElement>) =>
    setCategory(e.target.value);

  const handleSelectionUpdate = (_e: React.SyntheticEvent, selection: any) =>
    onSelectionUpdate(groupId, selection);

  const handleDelete = (tagId: number) => {
    const newSelection = selection.filter((v) => v.tag_id !== tagId);
    onSelectionUpdate(groupId, newSelection);
    setSelection(newSelection);
  };

  const renderGroup = (params: AutocompleteRenderGroupParams) => [
    <ListSubheader
      key={params.key}
      component="div"
      disableSticky
      className={styles.groupHeader}
    >
      {params.group}
    </ListSubheader>,
    params.children,
  ];

  return (
    <>
      <Grid item xs={6}>
        <TextField
          fullWidth
          id="category-select"
          label="Categories"
          variant="filled"
          select
          value={category}
          onChange={handleCategorySelect}
          InputProps={{ className: styles.textBox, disableUnderline: true }}
        >
          {categories.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={6}>
        <Autocomplete
          id="selection-box"
          multiple
          options={tags.filter((v) => v.tag_type === category)}
          disableCloseOnSelect
          value={selection}
          onChange={handleSelectionUpdate}
          groupBy={(option) => option.tag_group}
          getOptionLabel={(option) => option.tag_name}
          getOptionDisabled={() => selection.length === 10}
          renderGroup={renderGroup}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Variable"
              variant="filled"
              placeholder="Select Variables"
              fullWidth
              InputLabelProps={{ shrink: true }}
              InputProps={{
                ...params.InputProps,
                style: { borderRadius: "4px" },
                disableUnderline: true,
              }}
            />
          )}
          renderTags={() => null}
          classes={{ option: styles.listOption }}
        />
      </Grid>
      <Grid item xs={12}>
        <Paper elevation={0} className={styles.selectionPanel}>
          <div className={styles.rowDiv}>
            <Typography variant="body2" className={styles.selectionText}>
              Selection
            </Typography>
            <Typography variant="body2" className={styles.selectionCount}>
              ({selection.length}/10)
            </Typography>
          </div>
          {selection.map((v) => (
            <Chip
              className={styles.chip}
              key={v.tag_id}
              label={`${
                v.tag_type === "Demographics" ? v.tag_group : v.tag_type
              } = ${v.tag_name}`}
              onDelete={() => handleDelete(v.tag_id)}
              size="small"
              color="secondary"
            />
          ))}
        </Paper>
      </Grid>
    </>
  );
};

export default VariableSelection;
