import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

export const useExportStyles = makeStyles((theme: Theme) =>
  createStyles({
    groupHeader: {
      textTransform: "uppercase",
      fontFamily: "cera-pro-bold",
      color: theme.palette.primary.main,
      fontSize: "16px",
    },
    buttonGrid: {
      display: "flex",
      justifyContent: "flex-end",
    },
    cardHeader: {
      fontFamily: "cera-pro-bold",
      textTransform: "uppercase",
    },
    textBox: {
      borderRadius: "4px",
    },
  })
);
