import {
  Card,
  CardContent,
  Grid,
  InputAdornment,
  Typography,
} from "@mui/material";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import {
  DeleteOutlineOutlined,
  EditOutlined,
  Search,
  StarBorderRounded,
  StarRounded,
} from "@mui/icons-material";
import {
  SearchTextField,
  StyledCardHeader,
  StyledIconButton,
  tableStyles,
  useStyles,
} from "./SavedAudiences.styles";
import React, { useEffect } from "react";

import { Audience } from "@/types";
import { DeleteAudienceDialog } from "@/components/molecules";
import DialogContent from "@mui/material/DialogContent";
import { escapeRegExp } from "./utils";

interface SavedAudiencesProps {
  audiences: Array<Audience>;
  onAudienceDelete: (audienceName: string) => void;
  onAudienceEdit: (audience: Audience) => void;
  onAudienceFavourite: (audienceName: string, favourite: boolean) => void;
}

const SavedAudiences: React.FC<SavedAudiencesProps> = ({
  onAudienceFavourite,
  onAudienceDelete,
  onAudienceEdit,
  audiences,
}) => {
  const styles = useStyles();
  const tableClasses = tableStyles();

  const [searchText, setSearchText] = React.useState<string>("");
  const [rows, setRows] = React.useState<Array<Audience>>([]);
  const [audienceName, setAudienceName] = React.useState<string>("");
  const [deleteOpen, setDeleteOpen] = React.useState(false);

  useEffect(() => {
    setRows(audiences);
  }, [audiences]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchVal = e.target.value;
    setSearchText(searchVal);
    const searchRegex = new RegExp(escapeRegExp(searchVal), "i");
    const filteredRows = audiences.filter((a) => {
      return searchRegex.test(a.audience_name);
    });
    setRows(filteredRows);
  };

  const handleFavourite = (v: GridRenderCellParams) =>
    onAudienceFavourite(v.row.audience_name, !v.value);

  const prepDelete = (v: GridRenderCellParams) => {
    setAudienceName(v.row.audience_name);
    handleDeleteOpen();
  };

  const handleDelete = () => {
    onAudienceDelete(audienceName);
    setDeleteOpen(false);
  };

  const handleDeleteOpen = () => setDeleteOpen(true);

  const handleDeleteClose = () => setDeleteOpen(false);

  const handleEdit = (v: GridRenderCellParams) => onAudienceEdit(v.row);

  const renderFaveButton = (params: GridRenderCellParams) => {
    return (
      <StyledIconButton onClick={() => handleFavourite(params)} disableRipple>
        {params.value ? (
          <StarRounded color="primary" />
        ) : (
          <StarBorderRounded color="primary" />
        )}
      </StyledIconButton>
    );
  };

  const renderEditButton = (params: GridRenderCellParams) => {
    return (
      <StyledIconButton onClick={() => handleEdit(params)} disableRipple>
        <EditOutlined color="primary" />
      </StyledIconButton>
    );
  };

  const renderDeleteButton = (params: GridRenderCellParams) => {
    return (
      <StyledIconButton onClick={() => prepDelete(params)} disableRipple>
        <DeleteOutlineOutlined color="primary" />
      </StyledIconButton>
    );
  };

  const columns: GridColDef[] = [
    {
      field: "favourite",
      headerName: "Favourite",
      headerClassName: styles.tableHeader,
      flex: 1,
      align: "center",
      headerAlign: "center",
      sortable: false,
      renderCell: renderFaveButton,
    },
    {
      field: "audience_name",
      headerName: "Title",
      headerClassName: styles.tableHeader,
      cellClassName: styles.text,
      flex: 3,
    },
    {
      field: "created_at",
      headerName: "Created Date",
      headerClassName: styles.tableHeader,
      cellClassName: styles.dateText,
      valueFormatter: (param: string) => {
        return new Date(param).toUTCString();
      },
      type: "dateTime",
      flex: 2,
    },
    {
      field: "mainOperator",
      headerName: "Edit",
      headerClassName: styles.tableHeader,
      flex: 1,
      align: "center",
      headerAlign: "center",
      sortable: false,
      renderCell: renderEditButton,
    },
    {
      field: "mode",
      headerName: "Delete",
      headerClassName: styles.tableHeader,
      flex: 1,
      align: "center",
      headerAlign: "center",
      sortable: false,
      renderCell: renderDeleteButton,
    },
  ];

  return (
    <>
      <DialogContent
        style={{
          overflowY: "hidden",
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="body1">
              Select and manage any saved audiences you have created.
            </Typography>
          </Grid>
          <Grid item xs={12} className={styles.tableGrid}>
            <Card className={styles.dataCard} style={{ border: "none" }}>
              <StyledCardHeader
                title="Saved Audiences"
                titleTypographyProps={{ variant: "body1" }}
                className={styles.graphTitle}
                action={
                  <SearchTextField
                    id="search-bar"
                    variant="outlined"
                    size="small"
                    color="primary"
                    value={searchText}
                    onChange={handleSearch}
                    placeholder="Search..."
                    InputProps={{
                      startAdornment: (
                        <InputAdornment
                          className={styles.searchIcon}
                          position={"start"}
                        >
                          <Search color="primary" fontSize="small" />
                        </InputAdornment>
                      ),
                    }}
                  />
                }
                sx={{ mb: 1 }}
              />
              <CardContent
                className={styles.dataCardContent}
                style={{ border: "none" }}
              >
                <div style={{ height: 580 }}>
                  <DataGrid
                    rows={rows}
                    className={tableClasses.root}
                    columns={columns}
                    hideFooterSelectedRowCount
                    pagination
                    disableColumnFilter
                    disableColumnMenu
                    disableColumnResize
                    disableColumnSelector
                    disableRowSelectionOnClick
                    pageSizeOptions={[]}
                  />
                </div>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </DialogContent>
      <DeleteAudienceDialog
        open={deleteOpen}
        onClose={handleDeleteClose}
        onConfirm={handleDelete}
        audienceName={audienceName}
      />
    </>
  );
};

export default SavedAudiences;
