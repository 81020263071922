import { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import { customerConnect } from "@/api";
import { FileInfo } from "@/types";

const useConnect = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const postConnectData = async (
    fileInfo: FileInfo | null,
    selectionIds: number[]
  ) => {
    setLoading(true);
    setError(null);

    if (!fileInfo) return setError("No file info provided");

    try {
      const accessToken = await getAccessTokenSilently();
      const { data, error } = await customerConnect(
        accessToken,
        fileInfo,
        selectionIds
      );
      if (error) {
        setError(error.message);
      } else {
        return data;
      }
    } catch (err) {
      setError((err as Error).message);
    } finally {
      setLoading(false);
    }
  };

  return { postConnectData, loading, error };
};

export default useConnect;
