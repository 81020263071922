import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

export const useSizeResultsStyles = makeStyles((theme: Theme) =>
	createStyles({
		marginText: {
			marginRight: 4,
		},
		rowDiv: {
			display: "flex",
			flexDirection: "row",
		},
		rowDivSpacing: {
			display: "flex",
			flexDirection: "row",
			justifyContent: "space-evenly",
			marginBottom: 12,
		},
	})
);
