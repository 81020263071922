import React from "react";
import { Dialog, Grid, Typography } from "@mui/material";

import { InsightGraph } from "@/components/atoms";

import { PenPortraitInsightVariable } from "@/types";

interface InsightVariablesModalProps {
  open: boolean;
  variables: PenPortraitInsightVariable[];
  group: string;
  onClose: () => void;
}

const InsightVariablesModal: React.FC<InsightVariablesModalProps> = ({
  open,
  variables,
  group,
  onClose,
}) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
      <Grid container>
        <Typography variant="h6" style={{ padding: 8 }} color="primary">
          {group}
        </Typography>
        <Grid item xs={12}>
          <InsightGraph data={variables} />
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default InsightVariablesModal;
