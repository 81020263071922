import React, { useRef, useState, CSSProperties } from "react";
import { styled } from "@mui/material/styles";
import Popper from "@mui/material/Popper";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

interface GridCellExpandProps {
  value: string;
  width: number;
}

const CellRoot = styled("div")({
  alignItems: "center",
  lineHeight: "24px",
  height: "100%",
  position: "relative",
  display: "flex",
  "& .cellValue": {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
});

const isOverflown = (element: HTMLElement): boolean => {
  return (
    element.scrollHeight > element.clientHeight ||
    element.scrollWidth > element.clientWidth
  );
};

export function renderCellExpand(params: any) {
  return (
    <GridCellExpand
      value={params.value ? params.value.toString() : ""}
      width={params.colDef.computedWidth}
    />
  );
}

const GridCellExpand: React.FC<GridCellExpandProps> = ({ value, width }) => {
  const wrapper = useRef<HTMLDivElement | null>(null);
  const cellDiv = useRef<HTMLDivElement | null>(null);
  const cellValue = useRef<HTMLDivElement | null>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [showFullCell, setShowFullCell] = useState(false);
  const [showPopper, setShowPopper] = useState(false);

  const handleMouseEnter = () => {
    if (cellValue.current && cellDiv.current) {
      const isCurrentlyOverflown = isOverflown(cellValue.current);
      setShowPopper(isCurrentlyOverflown);
      setAnchorEl(cellDiv.current);
      setShowFullCell(true);
    }
  };

  const handleMouseLeave = () => {
    setShowFullCell(false);
  };

  const popperStyles: CSSProperties = {
    width: width + 8,
    marginLeft: -10,
    zIndex: 2,
  };

  return (
    <CellRoot
      ref={wrapper}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div
        ref={cellDiv}
        style={{
          height: 1,
          width,
          display: "block",
          position: "absolute",
          top: 0,
        }}
      />
      <div ref={cellValue} className="cellValue">
        {value}
      </div>
      {showPopper && (
        <Popper
          open={showFullCell && anchorEl !== null}
          anchorEl={anchorEl}
          placement="bottom-start"
          style={popperStyles}
        >
          <Paper
            elevation={0}
            style={{
              minHeight: wrapper.current ? wrapper.current.offsetHeight - 3 : 0,
            }}
          >
            <Typography variant="body1" style={{ padding: 10 }}>
              {value}
            </Typography>
          </Paper>
        </Popper>
      )}
    </CellRoot>
  );
};

export default GridCellExpand;
