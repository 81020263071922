import { createStyles, makeStyles } from "@mui/styles";

const usePageLayoutStyles = makeStyles(() =>
  createStyles({
    root: {
      width: "100%",
      display: "flex",
      padding: "0 1em",
      alignItems: "center",
      flexDirection: "column",
    },
    header: {
      display: "grid",
      alignItems: "end",
      borderBottom: "1px solid gray",
      justifyItems: "start",
      padding: "0em 0 1em",
      gridTemplateColumns: "1fr auto 1fr",
      width: "100%",
      "& h1": {
        fontSize: "1.5em",
      },
      textTransform: "uppercase",
    },
    content: {
      paddingTop: "1em",
      paddingBottom: 0,
      flex: "1 1 1px",
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },
    centerText: {
      textAlign: "center",
    },
    info: {
      justifySelf: "end",
    },
    boldTitle: {
      fontWeight: "bold",
    },
    dialogPaper: {
      paddingTop: "80px",
      maxHeight: "60vh",
    },
  })
);

export default usePageLayoutStyles;
