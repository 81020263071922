import { Logout, QuestionAnswer } from "@mui/icons-material";
import { Divider } from "@mui/material";
import React, { useState } from "react";

import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PersonIcon from "@mui/icons-material/Person";
import Typography from "@mui/material/Typography";
import { useAuth0 } from "@auth0/auth0-react";
import { useTheme } from "@mui/material/styles";

const UserProfile = () => {
	const theme = useTheme();
	const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
	const { user, logout } = useAuth0();

	const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorElUser(event.currentTarget);
	};

	const handleCloseUserMenu = () => {
		setAnchorElUser(null);
	};

	const handleLogout = () => {
		logout({
			returnTo: window.location.origin,
		});
	};

	const handleContactSupport = () => {
		window.location.href = "mailto:support@starcount.com";
	};

	return (
		<Box sx={{ flexGrow: 0 }}>
			<Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
				<Typography variant="body2" sx={{ mr: 1 }}>
					{user ? user.name : ""}
				</Typography>
				<IconButton onClick={handleOpenUserMenu}>
					<Avatar
						sx={{ bgcolor: theme.palette.text.primary, width: 30, height: 30 }}
					>
						<PersonIcon fontSize="small" />
					</Avatar>
				</IconButton>
			</Box>
			<Menu
				id="user-profile-menu"
				anchorEl={anchorElUser}
				anchorOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
				keepMounted
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
				open={Boolean(anchorElUser)}
				onClose={handleCloseUserMenu}
				sx={{ mt: "32px" }}
			>
				<MenuItem key="contact" onClick={handleContactSupport}>
					<Box sx={{ display: "flex", alignItems: "center" }}>
						<QuestionAnswer />
						<Typography sx={{ ml: 1 }} textAlign="center">
							Contact Support
						</Typography>
					</Box>
				</MenuItem>
				<Divider />
				<MenuItem key="logout" onClick={handleLogout}>
					<Box sx={{ display: "flex", alignItems: "center" }}>
						<Logout />
						<Typography sx={{ ml: 1 }} textAlign="center">
							Logout
						</Typography>
					</Box>
				</MenuItem>
			</Menu>
		</Box>
	);
};

export default UserProfile;
