import { AxiosRequestConfig } from "axios";

import { ApiError } from "@/interfaces/api";
import { fetchData } from "@/api/utils";

import { ValidationResult } from "@/types";

type ValidateDataApiResponse = {
  data: ValidationResult;
  error: ApiError | null;
};

export const validateData = async (
  accessToken: string,
  hasHeader: boolean,
  header: string,
  delimiter: string,
  file: File | null | undefined
): Promise<ValidateDataApiResponse> => {
  let url = `${process.env.REACT_APP_API_URL}/validate?delimiter=${delimiter}`;

  if (!hasHeader && header) url += `&header=${header}`;

  const config: AxiosRequestConfig = {
    method: "POST",
    url,
    headers: {
      "content-type": "text/csv",
      Authorization: `Bearer ${accessToken}`,
    },
    data: file,
  };

  return fetchData(config);
};
