import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

const useBuildStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardHeader: {
      fontFamily: 'cera-pro-bold',
      textTransform: 'uppercase',
      marginBottom: theme.spacing(2),
    },
    rowGrid: {
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(6),
    },
    title: {
      fontFamily: 'cera-pro-bold',
      textTransform: 'uppercase',
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(1),
    },
    subtitle: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
    },
  })
);

export default useBuildStyles;
