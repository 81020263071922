import { useThemeMode } from "@/context/theme-context";
import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { BarChart, TableChart } from "@mui/icons-material";
import MenuItem from "@mui/material/MenuItem";
import {
  Button,
  Grid,
  Tab,
  Tabs,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material";

import { ErrorPanel, FetchData } from "@/components/atoms";
import {
  GraphView,
  PenPortrait,
  SimpleMap,
  TableView,
} from "@/components/molecules";

import { tabs } from "@/constants";

import { CustomerDiscoverInsight, FileInfo } from "@/types";

import useStyles from "./CustomerDiscover.styles";
import useCustomerDiscover from "./use-customer-discover";

interface CustomerDiscoverProps {
  fileInfo: FileInfo | null;
  insightResult: CustomerDiscoverInsight | null;
  onInsightUpdate: (insight: CustomerDiscoverInsight) => void;
}

const CustomerDiscover: React.FC<CustomerDiscoverProps> = ({
  fileInfo,
  insightResult,
  onInsightUpdate,
}) => {
  const { darkMode } = useThemeMode();

  const { getAccessTokenSilently } = useAuth0();
  const styles = useStyles();
  const { getCustomerDiscoverData, loading, error } = useCustomerDiscover();

  const mapStyle = darkMode ? "dark" : "light";

  const [data, setData] = useState<CustomerDiscoverInsight | null>(null);
  const [tab, setTab] = useState<number>(0);
  const [segments, setSegments] = useState<string[]>([]);
  const [segment, setSegment] = useState<string>("");
  const [view, setView] = useState<string>("graph");
  const [scoreView, setScoreView] = useState<string>("index");
  const [permissions, setPermissions] = useState<string[]>([]);

  const showSnoop = permissions.includes("postcode:snoop");
  const showMastercard = permissions.includes("postcode:mastercard");

  useEffect(() => {
    const fetchPermissions = async () => {
      try {
        const token = await getAccessTokenSilently();
        const decodedToken: any = JSON.parse(atob(token.split(".")[1]));
        setPermissions(
          decodedToken[
            `https://${process.env.REACT_APP_AUTH0_DOMAIN}/permissions`
          ] || []
        );
      } catch (error) {
        console.error("Error fetching permissions:", error);
      }
    };

    fetchPermissions();
  }, [getAccessTokenSilently]);

  useEffect(() => {
    if (insightResult) {
      setData(insightResult);
      setSegments(insightResult.segments);
      setSegment(insightResult.segments[0]);
    }
  }, [insightResult]);

  const handleDiscover = async () => {
    const discoverData = await getCustomerDiscoverData(fileInfo);
    if (discoverData) onInsightUpdate(discoverData);
  };

  if (insightResult && data) {
    const result = data.insights[
      segments.length > 0 ? segments.indexOf(segment) : 0
    ]
      .filter((v) =>
        ["Map"].includes(tabs[tab])
          ? v.rank < 100 || v.tag_type === "Location"
          : v.tag_type === tabs[tab]
      )
      .sort((a, b) => b.score - a.score);

    const groups = Array.from(new Set(result.map((v) => v.tag_group)));
    const areaScores = result.filter((x) => x.tag_group === "Postal Area");
    const districtScores = result.filter(
      (x) => x.tag_group === "Postal District"
    );
    const sectorScores = result.filter((x) => x.tag_group === "Postal Sector");

    const handleViewChange = (
      _e: React.MouseEvent<HTMLElement>,
      newVal: string | null
    ) => {
      if (newVal !== null) setView(newVal);
    };

    const handleScoreViewChange = (
      _e: React.MouseEvent<HTMLElement>,
      newVal: string | null
    ) => {
      if (newVal !== null) setScoreView(newVal);
    };

    const renderView = () => {
      if (tabs[tab] === "Pen Portrait" && data) {
        return (
          <Grid container item xs={12} spacing={3} direction="row">
            <PenPortrait
              data={data.pen_portrait[segments.length > 0 ? segments.indexOf(segment) : 0]}
              mapStyle={mapStyle}
            />
          </Grid>
        );
      }

      if (tabs[tab] !== "Map") {
        if (view === "graph") {
          return (
            <Grid container item xs={12} spacing={3} direction="row">
              <GraphView tab={tabs[tab]} data={result} groups={groups} scoreView={scoreView} />
            </Grid>
          );
        }

        if (view === "table") {
          return (
            <Grid container item xs={12} spacing={3} direction="row">
              <TableView data={result} groups={groups} category={tabs[tab]} />
            </Grid>
          );
        }
      }

      return (
        <>
          {tabs[tab] === "Map" && (
            <Grid item xs={12} key={segment}>
              <div className="map-area">
                <SimpleMap
                  areaScores={areaScores}
                  districtScores={districtScores}
                  sectorScores={sectorScores}
                  mapStyle={mapStyle}
                />
              </div>
            </Grid>
          )}
        </>
      );
    };

    const renderTabs = (tabs: string[]) => {
      return tabs.map((category, idx) => {
        return (
          <Tab
            label={category}
            key={idx}
            disabled={
              (category === 'Spend Categories' && !showMastercard)
              || (category === 'Merchant Spending' && !showSnoop)
              || (category === 'Open Banking' && !showSnoop)
            }
          />
        );
      });
    };

    const handleSegmentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setSegment(e.target.value);
    };

    const handleTabChange = (e: React.ChangeEvent<{}>, newValue: number) =>
      setTab(newValue);

    return (
      <Grid container spacing={3} alignItems="center" alignContent="center">
        {segments.length > 0 && (
          <Grid item xs={12} className={styles.topPanel}>
            <TextField
              id="segment-select"
              label="Select a segment"
              variant="filled"
              select
              value={segment}
              onChange={handleSegmentChange}
              InputProps={{ disableUnderline: true }}
              style={{ width: "300px" }}
            >
              {segments.sort().map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
            {tabs[tab] !== "Map" && tabs[tab] !== "Pen Portrait" && (
              <Grid item xs={12} display="flex" justifyContent="flex-end">
                {view === "graph" && (
                  <div
                    style={{
                      marginRight: 20,
                    }}
                  >
                    <Typography variant="overline">Score Type</Typography>
                    <div>
                      <div>
                        <ToggleButtonGroup
                          value={scoreView}
                          onChange={handleScoreViewChange}
                          exclusive
                          size="small"
                          aria-label="score type change"
                        >
                          <ToggleButton value="score" aria-label="score">
                            <Tooltip title="Score" placement="left">
                              <>Score</>
                            </Tooltip>
                          </ToggleButton>
                          <ToggleButton value="index" aria-label="index">
                            <Tooltip title="Index" placement="right">
                              <>Index</>
                            </Tooltip>
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </div>
                    </div>
                  </div>
                )}
                <div>
                  <Typography variant="overline">View Type</Typography>
                  <div>
                    <ToggleButtonGroup
                      value={view}
                      onChange={handleViewChange}
                      exclusive
                      size="small"
                      aria-label="view change"
                    >
                      <ToggleButton value="table" aria-label="table">
                        <Tooltip title="Table view" placement="left">
                          <TableChart />
                        </Tooltip>
                      </ToggleButton>
                      <ToggleButton value="graph" aria-label="graph">
                        <Tooltip title="Graph view" placement="right">
                          <BarChart />
                        </Tooltip>
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </div>
                </div>
              </Grid>
            )}
          </Grid>
        )}
        <Grid item xs={12}>
          <Tabs
            value={tab}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            scrollButtons="auto"
            variant="scrollable"
          >
            {renderTabs(tabs)}
          </Tabs>
        </Grid>
        {renderView()}
      </Grid>
    );
  }

  if (loading) return <FetchData message="Calculating insight" />;

  if (error) return <ErrorPanel error={error} errorMessage={error} />;

  return (
    <Grid container spacing={3} alignItems="center" alignContent="center">
      <Grid item xs={12}>
        <Typography gutterBottom variant="h6" className={styles.cardHeader}>
          Discover
        </Typography>
        <Typography variant="body1" gutterBottom>
          Match your data to ours to understand the make-up and profile of your
          customers or prospect audience.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          size="large"
          onClick={handleDiscover}
        >
          Discover
        </Button>
      </Grid>
    </Grid>
  );
};

export default CustomerDiscover;
