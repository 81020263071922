import { createStyles, makeStyles } from '@mui/styles';

import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

export const useStyles = makeStyles(() =>
  createStyles({
    header: {
      borderBottom: '2px solid #EDF0F4',
      padding: '10px 16px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    content: {
      marginTop: '12px',
      padding: '12px',
    },
    actions: {
      padding: '12px',
    },
    text: {
      marginBottom: '24px',
    },
    headerText: {
      fontSize: '14px',
      marginBottom: '12px',
    },
    audienceNameBox: {
      minWidth: '300px',
      borderRadius: '6px',
    },
    title: {
      marginRight: '38px',
    },
    paper: {
      borderRadius: '10px',
    },
    rowDiv: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    warningText: {
      color: '#F0B248',
      opacity: 1,
      marginBottom: '4px',
    },
  })
);

export const AudienceTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    borderRadius: '6px',
    '& fieldset': {
      borderColor: (props: { error: boolean }) => props.error && '#F0B248',
    },
    '&:hover fieldset': {
      borderColor: (props: { error: boolean }) => props.error && '#F0B248',
      borderWidth: '2px',
    },
    '&:focus fieldset': {
      borderColor: (props: { error: boolean }) => props.error && '#F0B248',
      borderWidth: '2px',
    },
  },
});
