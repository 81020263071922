import {
  darkMapStyle,
  lightMapStyle,
} from "@/components/molecules/Map/PenPortraitMap/config";
import React, { useCallback } from "react";

import Map, { Source, Layer } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";

import { areaBaseLayer } from "./MapLayers";

import { InsightResult } from "@/types";

type PenPortraitMapProps = {
  areaScores: InsightResult[];
  mapStyle: string;
};

const PenPortraitMap: React.FC<PenPortraitMapProps> = ({
  areaScores,
  mapStyle,
}) => {
  const MAP_STYLE = mapStyle === "light" ? lightMapStyle : darkMapStyle;

  const onLoad = useCallback((event) => {
    const map = event.target;
    map &&
      areaScores.forEach((value) => {
        map.setFeatureState(
          {
            id: value.tag_id - 113,
            source: "area-source",
            sourceLayer: "areas_output",
          },
          { score: value.score_sum }
        );
      });
  }, [areaScores]);

  return (
    <Map
      key={mapStyle}
      initialViewState={{
        longitude: -4.27,
        latitude: 55,
        zoom: 4,
      }}
      projection={{ name: "mercator" }}
      minZoom={4}
      maxZoom={4}
      mapStyle={MAP_STYLE}
      mapboxAccessToken={process.env.REACT_APP_MAPBOX_MAP_TOKEN}
      attributionControl={false}
      onLoad={onLoad}
      interactiveLayerIds={["area-base"]}
      dragPan={false}
      dragRotate={false}
      style={{ borderRadius: "5px" }}
    >
      <Source
        id="area-source"
        type="vector"
        url="mapbox://adamsmithstarcount.1c7qwfks"
      >
        <Layer beforeId="waterway-label" {...areaBaseLayer} />
      </Source>
    </Map>
  );
};

export default PenPortraitMap;
