import React from "react";
import { Button, Divider, Grid, Tooltip } from "@mui/material";

interface DataPreviewProps {
  fileConfirmed: boolean;
  onClick: () => void;
}

const DataPreview: React.FC<DataPreviewProps> = ({ onClick }) => {
  const text =
    "Preview your data to check that everything is configured correctly";

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item container spacing={2} alignItems="center">
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={3}>
          <Tooltip title={text} placement="right">
            <Button
              fullWidth
              onClick={onClick}
              size="small"
              variant="contained"
              color={"primary"}
            >
              PREVIEW DATA
            </Button>
          </Tooltip>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DataPreview;
