import { createStyles, makeStyles } from "@mui/styles";

const useMapSwitchStyles = makeStyles(() =>
  createStyles({
    radioText: {
      fontSize: "10px",
    },
    button: {
      width: "65px",
    },
  })
);

export default useMapSwitchStyles;
