import { Spinner } from "@/components/atoms";
import { Grid } from "@mui/material";
import React, { ComponentType } from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { Route, RouteProps } from "react-router-dom";

interface ProtectedRouteProps extends RouteProps {
  component: ComponentType;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  component,
  ...args
}) => (
  <Route
    component={withAuthenticationRequired(component, {
      onRedirecting: () => (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{
            height: "100%",
          }}
        >
          <Spinner />
        </Grid>
      ),
    })}
    {...args}
  />
);

export default ProtectedRoute;
