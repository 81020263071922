import { saveAudience } from "@/api";
import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Typography,
} from "@mui/material";
import { AudienceTextField, useStyles } from "./SaveAudienceDialog.styles";

import { Audience, SaveAudienceResult, VariableGroup } from "@/types";

interface SaveAudienceDialogProps {
  audiences: Array<Audience>;
  geo: string;
  globalAudienceName: string;
  mainOperator: string;
  mode: string;
  open: boolean;
  onClose: () => void;
  onSavedAudience: (saved: boolean) => void;
  onUpdateAudiences: (audiences: Array<Audience>) => void;
  onUpdateAudienceName: (audienceName: string) => void;
  queryGroups: Array<VariableGroup>;
}

const SaveAudienceDialog: React.FC<SaveAudienceDialogProps> = ({
  audiences,
  geo,
  globalAudienceName,
  mainOperator,
  mode,
  open,
  onClose,
  onSavedAudience,
  onUpdateAudiences,
  onUpdateAudienceName,
  queryGroups,
}) => {
  const styles = useStyles();
  const { getAccessTokenSilently } = useAuth0();

  const [audienceName, setAudienceName] = useState<string>(globalAudienceName);
  const [audienceNameError, setAudienceNameError] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [saveError, setSaveError] = useState<boolean>(false);
  const [saveCheckOpen, setSaveCheckOpen] = useState<boolean>(false);

  useEffect(() => {
    audiences.find((audience) => audience.audience_name === audienceName)
      ? setAudienceNameError(true)
      : setAudienceNameError(false);
  }, []);

  const handleAudienceNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAudienceNameError(false);
    setAudienceName(e.target.value);
  };

  const handleSaveAudience = async () => {
    const token = await getAccessTokenSilently();

    setIsSaving(true);
    setSaveCheckOpen(true);

    const result: SaveAudienceResult = {
      audience_name: audienceName,
      var_groups: queryGroups,
      mainOperator,
      geo,
      mode,
    };

    const { data, error } = await saveAudience(token, result);

    if (data) {
      setIsSaving(false);
      onUpdateAudienceName(audienceName);
      onUpdateAudiences(data);
      onSavedAudience(true);
    }

    if (error) {
      setIsSaving(false);
      onSavedAudience(false);
      setSaveError(true);
    }
  };

  const handleSaveOpen = () => setSaveCheckOpen(true);

  const handleSaveClose = () => {
    setAudienceName("");
    setSaveCheckOpen(false);
    onClose();
  };

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth="sm"
      disableEnforceFocus
      aria-labelledby="save-dialog"
    >
      <DialogTitle id="save-dialog" className={styles.header}>
        <Typography className={styles.title}>Name this audience</Typography>
      </DialogTitle>

      <DialogContent className={styles.content}>
        <Typography className={styles.text}>
          To save this audience you need to give it a name.
        </Typography>
        <div className={styles.rowDiv}>
          <Typography className={styles.headerText}>Audience Name</Typography>
          {audienceNameError ? (
            <Typography variant="overline" className={styles.warningText}>
              Audience already exists
            </Typography>
          ) : null}
        </div>
        <AudienceTextField
          error={audienceNameError}
          variant="outlined"
          size="small"
          color="primary"
          fullWidth
          placeholder="Some Audience"
          value={audienceName}
          onChange={handleAudienceNameChange}
          className={styles.audienceNameBox}
        />
      </DialogContent>

      <DialogActions className={styles.actions}>
        <Button onClick={handleSaveClose} color="primary">
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={audienceNameError ? handleSaveOpen : handleSaveAudience}
          color="primary"
          disabled={audienceName === "" || isSaving || saveError}
        >
          Save Audience
        </Button>
        {isSaving ? <CircularProgress size={30} /> : null}
      </DialogActions>
      {audienceNameError && (
        <Dialog
          open={saveCheckOpen}
          disableEnforceFocus
          aria-labelledby="save-dialog"
        >
          <DialogTitle id="save-dialog" className={styles.header}>
            <Typography className={styles.title}>
              Audience already exists
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Typography>
              An audience with the name {audienceName} already exists. Do you
              want to overwrite it?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleSaveClose} color="primary">
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={handleSaveAudience}
              color="primary"
            >
              Replace
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Dialog>
  );
};

export default SaveAudienceDialog;
