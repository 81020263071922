import { SaveAudienceDialog } from "@/components/molecules";
import React, { useState } from "react";
import { NumericFormat } from "react-number-format";
import {
  Alert,
  Button,
  CircularProgress,
  Grid,
  Snackbar,
  Tooltip,
  Typography,
} from "@mui/material";

import {
  Audience,
  AudienceSize,
  ValidationResult,
  VariableGroup,
} from "@/types";

import useStyles from "./AudienceValidation.styles";

interface AudienceValidationProps {
  audiences: Array<Audience>;
  audienceSize: AudienceSize | null;
  hasValidated: boolean;
  geo: string;
  globalAudienceName: string;
  mainOperator: string;
  mode: string;
  onDataCheck: () => void;
  onUpdateAudiences: (audiences: Array<Audience>) => void;
  onUpdateAudienceName: (audienceName: string) => void;
  queryGroups: Array<VariableGroup>;
  validDisabled: boolean;
  validLoading: boolean;
  validationResult: ValidationResult | null;
}

const validateAudienceDataCheckText = "Validate the size of your Audience";
const saveAudienceDataCheckText = "Save your Audience";

const AudienceValidation: React.FC<AudienceValidationProps> = ({
  audiences,
  audienceSize,
  hasValidated,
  geo,
  globalAudienceName,
  mainOperator,
  mode,
  onDataCheck,
  onUpdateAudiences,
  onUpdateAudienceName,
  queryGroups,
  validDisabled,
  validLoading,
}) => {
  const styles = useStyles();

  const [saveOpen, setSaveOpen] = useState(false);
  const [audienceSaved, setAudienceSaved] = useState(false);

  const handleSaveOpen = () => setSaveOpen(true);

  const handleSaveClose = () => setSaveOpen(false);

  const onSavedAudience = (saved: boolean) => {
    if (saved) {
      setAudienceSaved(saved);
      handleSaveClose();
    }
  };

  const SizeResults = ({ results }: { results: AudienceSize }) => {
    return (
      <div className={styles.rowDiv}>
        <Typography variant="h6" className={styles.boldMarginText}>
          <NumericFormat
            value={results.num_postcodes}
            displayType={"text"}
            thousandSeparator={true}
          />
        </Typography>
        <Typography variant="subtitle1" className={styles.extraMarginText}>
          Postcodes
        </Typography>
        <Typography variant="h6" className={styles.boldMarginText}>
          <NumericFormat
            value={results.num_sectors}
            displayType={"text"}
            thousandSeparator={true}
          />
        </Typography>
        <Typography variant="subtitle1" className={styles.extraMarginText}>
          Sectors
        </Typography>
        <Typography variant="h6" className={styles.boldMarginText}>
          <NumericFormat
            value={results.num_households}
            displayType={"text"}
            thousandSeparator={true}
          />
        </Typography>
        <Typography variant="subtitle1">Households</Typography>
        <Typography variant="h6" className={styles.boldMarginText}>
          <NumericFormat
            value={results.audience_size}
            displayType={"text"}
            thousandSeparator={true}
          />
        </Typography>
        <Typography variant="subtitle1">Individuals</Typography>
      </div>
    );
  };

  return (
    <>
      <Grid item xs={12} container spacing={2} alignItems="center">
        <Grid item xs={3}>
          <Tooltip title={saveAudienceDataCheckText} placement="bottom">
            <div className={styles.wrapper}>
              <Button
                className={styles.validateButton}
                onClick={handleSaveOpen}
                size="large"
                variant="outlined"
                color="primary"
                disabled={!hasValidated || validDisabled}
              >
                SAVE AUDIENCE
              </Button>
            </div>
          </Tooltip>
          {saveOpen && audiences ? (
            <SaveAudienceDialog
              audiences={audiences}
              geo={geo}
              globalAudienceName={globalAudienceName}
              mainOperator={mainOperator}
              mode={mode}
              open={saveOpen}
              onClose={handleSaveClose}
              onSavedAudience={onSavedAudience}
              onUpdateAudiences={onUpdateAudiences}
              onUpdateAudienceName={onUpdateAudienceName}
              queryGroups={queryGroups}
            />
          ) : null}
        </Grid>
        <Grid item xs={3}>
          <Tooltip title={validateAudienceDataCheckText} placement="right">
            <div className={styles.wrapper}>
              <Button
                className={styles.validateButton}
                onClick={onDataCheck}
                size="large"
                variant="contained"
                color="primary"
                disabled={validLoading || validDisabled}
              >
                VALIDATE AUDIENCE
              </Button>
              {validLoading && (
                <CircularProgress size={24} className={styles.buttonProgress} />
              )}
            </div>
          </Tooltip>
        </Grid>
        <Grid item xs={9}>
          {audienceSize ? <SizeResults results={audienceSize} /> : null}
        </Grid>
      </Grid>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={audienceSaved}
        onClose={() => setAudienceSaved(false)}
        autoHideDuration={3000}
      >
        <Alert onClose={() => setAudienceSaved(false)} severity="success">
          Audience saved successfully!
        </Alert>
      </Snackbar>
    </>
  );
};

export default AudienceValidation;
