import React from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";

interface ExportDialogProps {
  open: boolean;
  onConfirm: () => void;
  onClose: () => void;
}

const ExportDialog: React.FC<ExportDialogProps> = ({
  onConfirm,
  open,
  onClose,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onConfirm}
      maxWidth={"sm"}
      fullWidth={true}
      aria-labelledby="export-dialog"
    >
      <DialogTitle id="export-title">
        Do you wish to continue with your export?
      </DialogTitle>
      <DialogContent>
        <Typography gutterBottom>
          If you are on a pay-as-you-go plan you may be charged. If you want to
          discuss your plan please contact a member of the Starcount team.
        </Typography>
        <Typography gutterBottom>Click Proceed to continue.</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} autoFocus color="primary">
          Cancel
        </Button>
        <Button
          onClick={onConfirm}
          variant="contained"
          autoFocus
          color="primary"
        >
          Proceed
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ExportDialog;
