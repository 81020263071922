import { StepIconRoot } from "./PathStepIcon.styles";
import React from "react";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import InsertChartOutlinedOutlinedIcon from "@mui/icons-material/InsertChartOutlinedOutlined";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import SupervisorAccountOutlinedIcon from "@mui/icons-material/SupervisorAccountOutlined";
import clsx from "clsx";

interface PathStepIconProps {
  active: boolean;
  completed: boolean;
  icon: number;
}

const PathStepIcon: React.FC<PathStepIconProps> = ({
  active,
  completed,
  icon,
}) => {
  const icons: { [key: number]: React.ReactElement } = {
    1: <DescriptionOutlinedIcon />,
    2: <InsertChartOutlinedOutlinedIcon />,
    3: <GroupAddIcon />,
    4: <SupervisorAccountOutlinedIcon />,
  };

  return (
    <StepIconRoot
      className={clsx({
        active: active,
        completed: completed,
      })}
    >
      {icons[icon]}
    </StepIconRoot>
  );
};

export default PathStepIcon;
