import {
  Button,
  CardHeader,
  IconButton,
  TextField,
  Theme,
} from "@mui/material";
import { styled, lighten } from "@mui/material/styles";
import { createStyles, makeStyles, withStyles } from "@mui/styles";

const height = 580;

export const useStyles = makeStyles(() =>
  createStyles({
    tableHeader: {
      fontSize: 12,
      textTransform: "uppercase",
    },
    title: {
      marginTop: "1em",
    },
    text: {
      fontSize: "14px",
    },
    dateText: {
      color: "#A2A9AD",
    },
    dataCard: {
      padding: "10px 0",
    },
    dataCardContent: {
      padding: "0px 0px 10px 0px",
      "&:last-child": {
        paddingBottom: "3px",
      },
    },
    graphTitle: {
      display: "flex",
      alignItems: "center",
    },
    cellRoot: {
      alignItems: "center",
      lineHeight: "24px",
      width: "100%",
      height: "100%",
      position: "relative",
      display: "flex",
      "& .cellValue": {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
    },
    actionButtons: {
      display: "flex",
      flex: 1,
      justifyContent: "flex-end",
      paddingTop: "0px !important",
    },
    tableGrid: {
      paddingBottom: "12px !important",
    },
    searchIcon: {
      marginRight: "6px",
    },
  })
);

export const tableStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      border: "none",
      height: `100%`,
      "& .MuiDataGrid-iconSeparator": {
        display: "none",
      },
      "& .MuiDataGrid-columnsContainer": {
        borderBottom: "none",
      },
      "& .MuiDataGrid-columnHeaders": {
        borderBottom: "none",
      },
      "& .MuiDataGrid-columnHeader": {
        backgroundColor: lighten(theme.palette.background.paper, 0.05),
      },
      "& .MuiDataGrid-row:hover": {
        color: theme.palette.primary.main,
        boxShadow: "0px 17px 50px -10px #01214E19",
        zIndex: 2,
      },
      "& .MuiDataGrid-row.Mui-selected": {
        boxSizing: "border-box",
        border: "1px solid #00A1D2",
      },
      "& .MuiDataGrid-cell": {
        borderBottom: "none",
      },
      "& .MuiDataGrid-cell:focus": {
        outline: "none",
      },
      "& .MuiDataGrid-cell:focus-within": {
        outline: "none",
      },
      "& .MuiDataGrid-columnHeader:focus-within": {
        outline: "none",
      },
      "& .MuiDataGrid-withBorder": {
        borderRight: "none",
      },
      "& .MuiDataGrid-viewport": {
        maxHeight: "unset !important",
      },
      "& .MuiDataGrid-renderingZone": {
        maxHeight: `${height}px !important`,
      },
      "& .MuiDataGrid-virtualScroller": {
        border: "none",
      },
      "& .MuiDataGrid-footerContainer": {
        borderTop: "none",
      },
    },
  })
);

export const StyledCardHeader = withStyles({
  root: {
    padding: "4px 16px 4px 16px",
  },
  action: {
    margin: 0,
  },
})(CardHeader);

export const NameButton = withStyles((theme) => ({
  root: {
    height: "30px",
    justifyContent: "flex-start",
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
}))(Button);

export const StyledIconButton = withStyles({
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
})(IconButton);

export const SearchTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    paddingLeft: "8px",
    height: "36px",
    "& fieldset": {
      borderColor: theme.palette.primary.main,
    },
    "&:hover fieldset": {
      borderColor: theme.palette.primary.main,
      borderWidth: "2px",
    },
  },
}));
