import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { lighten } from "@mui/material/styles";

const useVariableSelectionStyles = makeStyles((theme: Theme) =>
  createStyles({
    chip: {
      margin: "8px",
      borderRadius: "6px",
      paddingTop: "2px",
    },
    groupHeader: {
      textTransform: "uppercase",
      fontFamily: "cera-pro-bold",
      color: theme.palette.primary.main,
      fontSize: "16px",
    },
    listOption: {
      fontSize: "16px",
      marginRight: "8px",
    },
    selectionText: {
      paddingLeft: "12px",
      paddingTop: "10px",
      color: theme.palette.text.primary,
      fontSize: "0.75rem",
      lineHeight: "1",
    },
    selectionCount: {
      textAlign: "right",
      paddingRight: "12px",
      paddingTop: "10px",
      color: theme.palette.text.primary,
      fontSize: "0.75rem",
      lineHeight: "1",
    },
    selectionPanel: {
      background: lighten(theme.palette.background.paper, 0.14),
      minHeight: "62px",
    },
    rowDiv: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    textBox: {
      borderRadius: "4px",
    },
  })
);

export default useVariableSelectionStyles;
