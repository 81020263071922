import { tabs } from "@/constants";
import { useThemeMode } from "@/context/theme-context";
import { useAuth0 } from "@auth0/auth0-react";
import { BarChart, TableChart } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import {
  Button,
  Grid,
  Tab,
  Tabs,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material";

import { ErrorPanel, FetchData } from "@/components/atoms";
import {
  GraphView,
  PenPortrait,
  SimpleMap,
  TableView,
} from "@/components/molecules";

import { AudienceDiscoverInsight, AudienceSize, VariableGroup } from "@/types";

import useStyles from "./AudienceDiscover.styles";
import useAudienceDiscover from "./use-audience-discover";

interface AudienceDiscoverProps {
  queryGroups: Array<VariableGroup>;
  audienceSize: AudienceSize | null;
  geo: string;
  insightResult: AudienceDiscoverInsight | null;
  onInsightUpdate: (insightResult: AudienceDiscoverInsight) => void;
}

const AudienceDiscover: React.FC<AudienceDiscoverProps> = ({
  audienceSize,
  geo,
  insightResult,
  onInsightUpdate,
}) => {
  const { darkMode } = useThemeMode();

  const { getAccessTokenSilently } = useAuth0();
  const styles = useStyles();
  const { getAudienceDiscoverData, loading, error } = useAudienceDiscover();

  const mapStyle = darkMode ? "dark" : "light";

  const [data, setData] = useState<AudienceDiscoverInsight | null>(null);
  const [tab, setTab] = useState(0);
  const [view, setView] = useState<string>("graph");
  const [scoreView, setScoreView] = useState<string>("index");
  const [permissions, setPermissions] = useState<string[]>([]);

  const showSnoop = permissions.includes("postcode:snoop");
  const showMastercard = permissions.includes("postcode:mastercard");

  useEffect(() => {
    const fetchPermissions = async () => {
      try {
        const token = await getAccessTokenSilently();
        const decodedToken: any = JSON.parse(atob(token.split(".")[1]));
        setPermissions(
          decodedToken[
            `https://${process.env.REACT_APP_AUTH0_DOMAIN}/permissions`
          ] || []
        );
      } catch (error) {
        console.error("Error fetching permissions:", error);
      }
    };

    fetchPermissions();
  }, [getAccessTokenSilently]);

  useEffect(() => {
    if (insightResult) setData(insightResult);
  }, [insightResult]);

  const handleDiscover = async () => {
    if (audienceSize) {
      const data = await getAudienceDiscoverData(
        audienceSize.request_id || "",
        geo
      );
      if (data) {
        onInsightUpdate(data);
      }
    }
  };

  if (insightResult && data) {
    const result = data.insights.filter((v) =>
      ["Map"].includes(tabs[tab])
        ? v.rank < 100 || v.tag_type === "Location"
        : v.tag_type === tabs[tab]
    );

    if (result) {
      const groups = Array.from(new Set(result.map((v) => v.tag_group)));
      const areaScores = result.filter((x) => x.tag_group === "Postal Area");
      const districtScores = result.filter((x) => x.tag_group === "Postal District");
      const sectorScores = result.filter((x) => x.tag_group === "Postal Sector");

      const handleViewChange = (
        _e: React.MouseEvent<HTMLElement>,
        newVal: string | null
      ) => {
        if (newVal !== null) setView(newVal);
      };

      const handleScoreViewChange = (
        _e: React.MouseEvent<HTMLElement>,
        newVal: string | null
      ) => {
        if (newVal !== null) setScoreView(newVal);
      };

      const renderView = () => {
        if (tabs[tab] === "Pen Portrait" && data) {
          return (
            <Grid container item xs={12} spacing={3} direction="row">
              <PenPortrait data={data.pen_portrait} mapStyle={mapStyle} />
            </Grid>
          );
        }

        if (tabs[tab] !== "Map") {
          if (view === "graph") {
            return (
              <Grid container item xs={12} spacing={3} direction="row">
                <GraphView
                  tab={tabs[tab]}
                  data={result}
                  groups={groups}
                  scoreView={scoreView}
                />
              </Grid>
            );
          }

          if (view === "table") {
            return (
              <Grid container item xs={12} spacing={3} direction="row">
                <TableView data={result} groups={groups} category={tabs[tab]} />
              </Grid>
            );
          }
        }

        return (
          <>
            {tabs[tab] === "Map" && (
              <Grid item xs={12}>
                <div className="map-area">
                  <SimpleMap
                    areaScores={areaScores}
                    districtScores={districtScores}
                    sectorScores={sectorScores}
                    mapStyle={mapStyle}
                  />
                </div>
              </Grid>
            )}
          </>
        );
      };

      const renderTabs = (tabs: string[]) => {
        return tabs.map((category, idx) => (
          <Tab
            label={category}
            key={idx}
            disabled={
              (category === 'Spend Categories' && !showMastercard)
              || (category === 'Merchant Spending' && !showSnoop)
              || (category === 'Open Banking' && !showSnoop)
            }
          />
        ));
      };

      const handleTabChange = (e: React.ChangeEvent<{}>, newValue: number) => {
        setTab(newValue);
      };

      return (
        <Grid container spacing={3} alignItems="center" alignContent="center">
          <Grid item xs={12}>
            <Tabs
              value={tab}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="primary"
              scrollButtons="auto"
              variant="scrollable"
            >
              {renderTabs(tabs)}
            </Tabs>
          </Grid>
          {tabs[tab] !== "Map" && tabs[tab] !== "Pen Portrait" && (
            <Grid item xs={12} display="flex" justifyContent="flex-end">
              {view === "graph" && (
                <div
                  style={{
                    marginRight: 20,
                  }}
                >
                  <Typography variant="overline">Score Type</Typography>
                  <div>
                    <div>
                      <ToggleButtonGroup
                        value={scoreView}
                        onChange={handleScoreViewChange}
                        exclusive
                        size="small"
                        aria-label="score type change"
                      >
                        <ToggleButton value="score" aria-label="score">
                          <Tooltip title="Score" placement="left">
                            <>Score</>
                          </Tooltip>
                        </ToggleButton>
                        <ToggleButton value="index" aria-label="index">
                          <Tooltip title="Index" placement="right">
                            <>Index</>
                          </Tooltip>
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </div>
                  </div>
                </div>
              )}
              <div>
                <Typography variant="overline">View Type</Typography>
                <div>
                  <ToggleButtonGroup
                    value={view}
                    onChange={handleViewChange}
                    exclusive
                    size="small"
                    aria-label="view change"
                  >
                    <ToggleButton value="table" aria-label="table">
                      <Tooltip title="Table view" placement="left">
                        <TableChart />
                      </Tooltip>
                    </ToggleButton>
                    <ToggleButton value="graph" aria-label="graph">
                      <Tooltip title="Graph view" placement="right">
                        <BarChart />
                      </Tooltip>
                    </ToggleButton>
                  </ToggleButtonGroup>
                </div>
              </div>
            </Grid>
          )}
          {renderView()}
        </Grid>
      );
    }
  }

  if (loading) return <FetchData message="Calculating insight" />;

  if (error) return <ErrorPanel error={error} errorMessage={error} />;

  return (
    <Grid container spacing={3} alignItems="center" alignContent="center">
      <Grid item xs={12}>
        <Typography gutterBottom variant="h6" className={styles.cardHeader}>
          Discover
        </Typography>
        <Typography variant="body1" gutterBottom>
          Understand the make-up and profile of your campaign audience.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          size="large"
          onClick={handleDiscover}
        >
          Discover
        </Button>
      </Grid>
    </Grid>
  );
};

export default AudienceDiscover;
