import React from "react";
import { ErrorOutline } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Typography,
} from "@mui/material";
import useDeleteAudienceDialogStyles from "./DeleteAudienceDialog.styles";

interface DeleteAudienceDialogProps {
  audienceName: string;
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const DeleteAudienceDialog: React.FC<DeleteAudienceDialogProps> = ({
  audienceName,
  open,
  onClose,
  onConfirm,
}) => {
  const styles = useDeleteAudienceDialogStyles();

  return (
    <Dialog
      open={open}
      disableEnforceFocus
      aria-labelledby="delete-dialog"
      classes={{ paper: styles.paper }}
    >
      <DialogTitle id="delete-dialog" className={styles.header}>
        <div className={styles.rowDiv}>
          <ErrorOutline className={styles.icon} />
          <Typography variant="h2" className={styles.title}>
            Are you sure you want to delete this audience?
          </Typography>
        </div>
      </DialogTitle>
      <DialogContent className={styles.content}>
        <Typography>
          Please confirm you wish to delete {audienceName} from your saved
          audiences.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} classes={{ root: styles.buttonOutlined }}>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={onConfirm}
          classes={{ root: styles.buttonContained }}
        >
          Delete Audience
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteAudienceDialog;
