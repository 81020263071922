import {styled} from "@mui/material/styles";

export const StepIconRoot = styled("div")(({ theme }) => ({
	backgroundColor: theme.palette.background.paper,
	zIndex: 1,
	color: "#fff",
	width: 50,
	height: 50,
	display: "flex",
	borderRadius: "50%",
	justifyContent: "center",
	alignItems: "center",
	"&.active": {
		backgroundColor: theme.palette.primary.dark,
		boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
		color: theme.palette.primary.contrastText
	},
	"&.completed": {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.primary.contrastText
	},
}));
