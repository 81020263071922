import React from "react";
import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";

interface ErrorPanelProps {
  error: string;
  errorMessage: string;
}

const ErrorPanel: React.FC<ErrorPanelProps> = ({ errorMessage, error }) => {
  return (
    <Accordion
      style={{
        color: "white",
        marginBottom: "10px",
        maxWidth: "500px",
        alignSelf: "center",
      }}
    >
      <AccordionSummary expandIcon={errorMessage ? <ExpandMore /> : null}>
        <Typography variant="subtitle1">
          {error}. {errorMessage ? "Expand to see." : ""}
        </Typography>
      </AccordionSummary>
      {errorMessage ? (
        <AccordionDetails>
          <textarea
            style={{
              fontFamily: "monospace",
              fontSize: "12px",
              background: "none",
              width: "100%",
              resize: "none",
              minHeight: "200px",
              color: "white",
              border: "1px solid #FFFFFF45",
            }}
            defaultValue={JSON.stringify(errorMessage, null, 2)}
          />
        </AccordionDetails>
      ) : null}
    </Accordion>
  );
};

export default ErrorPanel;
