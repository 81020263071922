import { FillLayer } from "react-map-gl";

export const areaBaseLayer: FillLayer = {
  id: "area-base",
  type: "fill",
  "source-layer": "areas_output",
  paint: {
    // "fill-outline-color": "rgba(0,0,0,0.1)",
    "fill-color": [
      "case",
      ["==", ["feature-state", "score"], null],
      "#ECF3F4",
      [
        "interpolate",
        ["linear"],
        ["feature-state", "score"],
        0, '#212b3a',
        0.2, '#354a5f',
        0.4, '#486d84',
        0.5, '#5a92aa',
        0.7, '#6db9cf',
        0.8, '#82e1f2',
      ],
    ],
    "fill-opacity": [
      "case",
      ["==", ["feature-state", "score"], null],
      0,
      ["case", ["<", ["feature-state", "score"], 0], 0, 1],
    ],
  },
};
