class RowsExtractor {
  promise: Promise<string[]>;
  private isDestroyed = false;

  constructor(file: File, rowsToExtract: number) {
    const CHUNK_SIZE_IN_BYTES = 8;

    this.promise = this.extractRows(file, rowsToExtract, CHUNK_SIZE_IN_BYTES);
  }

  private async extractRows(
    file: File,
    rowsToExtract: number,
    chunkSize: number
  ): Promise<string[]> {
    const fileReader = new FileReader();
    let offset = 0;
    let data = "";
    let rows = 0;

    while (true) {
      if (this.isDestroyed) {
        fileReader.abort();
        return [];
      }

      const blob = file.slice(offset, offset + chunkSize);

      if (blob.size === 0) {
        break;
      }

      fileReader.readAsText(blob);

      await new Promise<void>((resolve) => {
        const onLoad = (e: ProgressEvent<FileReader>) => {
          const chunk = e.target?.result as string;

          data += chunk;
          rows += chunk.split("").filter((char) => char === "\n").length;

          fileReader.removeEventListener("load", onLoad);

          resolve();
        };

        fileReader.addEventListener("load", onLoad);
      });

      if (rows === rowsToExtract) {
        break;
      }

      offset += chunkSize;
    }

    return data.split("\n").slice(0, rowsToExtract);
  }

  public getPromise(): Promise<string[]> {
    return this.promise;
  }

  public destroy(): void {
    this.isDestroyed = true;
  }
}

export default RowsExtractor;
