import { Spinner, UserProfile } from "@/components/atoms";
import { useThemeMode } from "@/context/theme-context";
import { useAuth0 } from "@auth0/auth0-react";
import {
  AppBar,
  Box,
  Grid,
  IconButton,
  Stack,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React from "react";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import { Logo, LogoDark } from "@/assets/images";

interface PageLayoutProps {
  children?: React.ReactNode;
}

const MainLayout: React.FC<PageLayoutProps> = ({ children }) => {
  const theme = useTheme();
  const { toggleDarkMode } = useThemeMode();
  const { isLoading, isAuthenticated } = useAuth0();
  const mode = theme.palette.mode;

  if (isLoading) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{
          height: "100%",
        }}
      >
        <Spinner />
      </Grid>
    );
  }

  return (
    <Box minWidth={1280}>
      <AppBar
        color={mode === "dark" ? "primary" : "inherit"}
        position="static"
        elevation={0}
      >
        <Toolbar variant="dense" sx={{ justifyContent: "space-between" }}>
          <Stack direction="row" spacing={0} sx={{ alignItems: "center" }}>
            <Box sx={{ flexGrow: 0, width: "148px", height: "32px", mr: 2 }}>
              {mode === "dark" ? <Logo /> : <LogoDark />}
            </Box>
          </Stack>
          {/*<Typography variant="h6" sx={{ textTransform: "uppercase" }}>*/}
          {/*  Connect*/}
          {/*</Typography>*/}
          <Stack direction="row" spacing={2} alignItems={"center"}>
            <Tooltip
              title={mode === "dark" ? "Toggle Light Mode" : "Toggle Dark Mode"}
            >
              <IconButton onClick={toggleDarkMode} color="default" size="small">
                {mode === "dark" ? <Brightness4Icon /> : <Brightness7Icon />}
              </IconButton>
            </Tooltip>
            <UserProfile />
          </Stack>
        </Toolbar>
      </AppBar>
      <Box
        sx={{
          flexGrow: 1,
          p: 3,
          height: "calc(100vh - 48px)",
          overflow: "auto",
          backgroundColor: theme.palette.background.default,
        }}
      >
        {isAuthenticated ? (
          <Grid
            item
            xs={12}
            style={{
              width: "100%",
              height: "100%",
              display: "grid",
              paddingTop: "2em",
              alignContent: "start",
              justifyContent: "center",
              gridTemplateColumns: "90%",
            }}
          >
            {children}
          </Grid>
        ) : (
          <Typography>You do not have permissions to view this App.</Typography>
        )}
      </Box>
    </Box>
  );
};

export default MainLayout;
