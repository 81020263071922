import React from "react";
import { MapboxGeoJSONFeature } from "react-map-gl";

import { Box, Typography, Card, CardContent } from "@mui/material";
import { toPercentage } from "@/utils/common";

type SimpleOverlayProps = {
  breaks: number[];
  selected?: MapboxGeoJSONFeature;
};

const SimpleOverlay: React.FC<SimpleOverlayProps> = ({ breaks, selected }) => {

  return (
    <>
    <Box sx={{ position: "relative" }}>
      {selected && Object.keys(selected.state).length > 0 && (
        <Card sx={{ position: "absolute", top: 12, right: 12 }}>
          <CardContent
            sx={{ p: 2, "&:last-child": { pb: 2 }, minWidth: "150px" }}
          >
            <Typography>{`${selected?.state.level}: ${selected?.state.name}`}</Typography>
            <Typography variant="body2">{`Penetration: ${toPercentage(
              selected?.state.percentage
            )}`}</Typography>
          </CardContent>
        </Card>
      )}

    </Box>
    <Box
      sx={{
        position: "absolute",
        bottom: 40,
        left: 12,
        width: '150px',
      }}
    >
      <Box sx={{
        width: '150px',
        height: '10px',
        borderRadius: '10px',
        backgroundImage: 'linear-gradient(to right, #354a5f, #486d84, #6db9cf, #82e1f2)'
      }}/>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <Typography variant='caption'>{toPercentage(breaks[0], 0)}</Typography>
        <Typography variant='caption'>{toPercentage(breaks[1], 0)}</Typography>
        <Typography variant='caption'>{toPercentage(breaks[2], 0)}</Typography>
      </Box>
    </Box>
    </>
  );
};

export default SimpleOverlay;
