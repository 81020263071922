import { Button, Divider, Grid } from "@mui/material";
import React from "react";

interface PathProps {
  children: React.ReactNode;
  phase: number;
  steps: string[];
  handleBack: () => void;
  handleNext: () => void;
  backDisabled?: boolean;
  nextDisabled?: boolean;
}
const Path = ({
  children,
  phase,
  steps,
  handleBack,
  handleNext,
  backDisabled,
  nextDisabled,
}: PathProps) => {
  return (
    <Grid container spacing={3} alignItems="center" alignContent="center">
      <Grid item xs={2}>
        <Button
          variant="outlined"
          color="primary"
          fullWidth
          disabled={backDisabled}
          onClick={handleBack}
        >
          Back
        </Button>
      </Grid>
      <Grid item xs={8} />
      <Grid item xs={2}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          disabled={nextDisabled}
          onClick={handleNext}
        >
          {phase === steps.length - 1 ? "Finish" : "Next"}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        {children}
      </Grid>
    </Grid>
  );
};

export default Path;
