import { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import { exportAudience } from "@/api";
import { AudienceSize } from "@/types";

const useExportAudience = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const exportAudienceData = async (
    selectionIds: number[],
    exportOption: string,
    audienceSize: AudienceSize
  ) => {
    setLoading(true);
    setError(null);

    try {
      const accessToken = await getAccessTokenSilently();
      const { data, error } = await exportAudience(
        accessToken,
        selectionIds,
        exportOption,
        audienceSize
      );
      if (error) {
        setError(error.message);
      } else {
        return data;
      }
    } catch (err) {
      setError((err as Error).message);
    } finally {
      setLoading(false);
    }
  };

  return { exportAudienceData, loading, error };
};

export default useExportAudience;
