import React from "react";
import {
  Button,
  CircularProgress,
  Divider,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";
import { ValidationResult } from "@/types";
import SizeResults from "./SizeResults";
import Summary from "./Summary";
import useValidationStyles from "./Validation.styles";

interface ValidationProps {
  fileConfirmed: boolean;
  onDataCheck: () => void;
  results: ValidationResult | null;
  valid: boolean;
  validLoading: boolean;
  validError: boolean;
}

const Validation: React.FC<ValidationProps> = ({
  fileConfirmed,
  onDataCheck,
  results,
  valid,
  validLoading,
  validError,
}) => {
  const dataCheckText = `Check that your data is formatted correctly and your postcodes are valid`;
  const styles = useValidationStyles();

  const ValidationSummary = ({ results }: { results: ValidationResult }) => {
    return (
      <div>
        <div className={styles.rowDivSpacing}>
          <Summary type={"schema"} results={results} />
          <Summary type={"data"} results={results} />
        </div>
        <div>
          <SizeResults results={results} />
        </div>
      </div>
    );
  };

  const ValidationError = () => {
    return (
      <div className={styles.rowDiv}>
        <Typography variant="body2">
          There was a problem with the request. Please try again.
        </Typography>
      </div>
    );
  };

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item container spacing={2} alignItems="center">
        <Grid item xs={3}>
          <Tooltip title={dataCheckText} placement="right">
            <div className={styles.wrapper}>
              <Button
                className={styles.validateButton}
                onClick={onDataCheck}
                size="small"
                variant="contained"
                color={valid || fileConfirmed ? "primary" : "primary"}
                disabled={validLoading}
              >
                VALIDATE DATA
              </Button>
              {validLoading && (
                <CircularProgress size={24} className={styles.buttonProgress} />
              )}
            </div>
          </Tooltip>
        </Grid>
        <Grid item xs={9}>
          {valid && results ? <ValidationSummary results={results} /> : null}
          {validError ? <ValidationError /> : null}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
    </Grid>
  );
};

export default Validation;
