import { createStyles, makeStyles } from "@mui/styles";

const AudienceValidationStyles = makeStyles((theme) =>
  createStyles({
    boldMarginText: {
      marginRight: 8,
      marginLeft: 64,
      fontFamily: "cera-pro-bold",
    },
    extraMarginText: {
      marginRight: 32,
    },
    validateButton: {
      width: "100%",
    },
    rowDiv: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    wrapper: {
      position: "relative",
    },
    buttonProgress: {
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },
  })
);

export default AudienceValidationStyles;
