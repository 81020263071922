import React from "react";
import { Typography } from "@mui/material";
import { NumericFormat } from "react-number-format";
import { useSizeResultsStyles } from "./SizeResults.styles";
import { ValidationResult, ValidationSegment } from "@/types";

interface SizeResultsProps {
  results: ValidationResult;
}

const SizeResults: React.FC<SizeResultsProps> = ({ results }) => {
  const styles = useSizeResultsStyles();

  if (!results.data) return null;

  const renderSegment = (segment: ValidationSegment, index: number) => (
    <div key={index} className={styles.rowDivSpacing}>
      <div className={styles.rowDiv}>
        <Typography variant="body2" className={styles.marginText}>
          Segment:
        </Typography>
        <Typography variant="body2">{segment.segment}</Typography>
      </div>
      <div className={styles.rowDiv}>
        <Typography variant="body2" className={styles.marginText}>
          Valid Postcodes:
        </Typography>
        <Typography variant="body2">
          <NumericFormat
            value={segment.num_valid}
            displayType={"text"}
            thousandSeparator={true}
          />
        </Typography>
      </div>
      <div className={styles.rowDiv}>
        <Typography variant="body2" className={styles.marginText}>
          Invalid Postcodes:
        </Typography>
        <Typography variant="body2">
          <NumericFormat
            value={segment.num_invalid}
            displayType={"text"}
            thousandSeparator={true}
          />
        </Typography>
      </div>
    </div>
  );

  return (
    <div>
      {results.segments && results.segments.length > 0 ? (
        results.segments.map((segment, index) => renderSegment(segment, index))
      ) : (
        <div className={styles.rowDivSpacing}>
          <div className={styles.rowDiv}>
            <Typography variant="body2" className={styles.marginText}>
              <NumericFormat
                value={results.num_valid}
                displayType={"text"}
                thousandSeparator={true}
              />
            </Typography>
            <Typography variant="body2">valid postcodes</Typography>
          </div>
          <div className={styles.rowDiv}>
            <Typography variant="body2" className={styles.marginText}>
              <NumericFormat
                value={results.num_invalid}
                displayType={"text"}
                thousandSeparator={true}
              />
            </Typography>
            <Typography variant="body2">invalid postcodes</Typography>
          </div>
          <div className={styles.rowDiv}>
            <Typography variant="body2" color="primary">
              No segments uploaded
            </Typography>
          </div>
        </div>
      )}
    </div>
  );
};

export default SizeResults;
