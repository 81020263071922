import { AxiosRequestConfig } from "axios";

import { ApiError } from "@/interfaces/api";
import { InsightResult, PenPortraitApiResponse} from "@/types";

import { fetchData } from "@/api/utils";

type DiscoverAudienceApiResponse = {
  data: {
    insight: Array<InsightResult>;
    pen_portrait: PenPortraitApiResponse;
  };
  error: ApiError | null;
};

const discoverAudiences = async (
  accessToken: string,
  requestId: string,
  geo: string
): Promise<DiscoverAudienceApiResponse> => {
  let url = `${process.env.REACT_APP_API_URL}/campaign/discover?request_id=${requestId}&geo=${geo}`;

  const config: AxiosRequestConfig = {
    method: "GET",
    url,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  return fetchData(config);
};

export default discoverAudiences;
